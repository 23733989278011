import FormOfPaymentsList from '../form-of-payments-list/form-of-payments-list.component';
import { useState, useContext, useEffect, Fragment } from "react";
import { UserContext } from '../../contexts/user.context';
import { getFacilityCustomerPaymentMethods } from "../../utils/facility-api/facility-service";
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import AddFormOfPayment from '../add-form-of-payment/add-form-of-payment.component';
import { Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';


const FormOfPayment = ({ selectFormOfPaymentType, selectFormOfPayment, formOfPaymentType, formOfPayment, facilityCustomer, allowFOPSelection = true, allowCashCheck = true, isInternalBooking = false, mainColor = "#14254C", secondaryColor = "#A9A9A9", allowInvoice = true }) => {
    const [formsOfPayment, setFormsOfPayment] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const [addFormOfPayment, setAddFormOfPayment] = useState(false);
    const [formOfPaymentTypes, setFormOfPaymentTypes] = useState(["Credit Card"]);

    const getFormsOfPayment = async () => {
        const formsOfPaymentResponse = await getFacilityCustomerPaymentMethods(userData.stripeCustomerId, facilityCustomer.stripeCustomerId);
        if (formsOfPaymentResponse.status === 200) {

            let formsOfPayment = formsOfPaymentResponse.data.data;
            formsOfPayment.unshift({
                id: "Add Form Of Payment",
                type: "Add_Form_Of_Payment"
            })
            setFormsOfPayment(formsOfPayment);
        } else {
            setFormsOfPayment([]);
        }
        setIsLoading(false);
    };

    useEffect(() => {

        if (!isInternalBooking){
            if (allowCashCheck) {
                setFormOfPaymentTypes(["Credit Card", "Cash/Check"]);
            }
    
            if (facilityCustomer.stripeCustomerId && formsOfPayment.length <= 0) {
                getFormsOfPayment();
            } else {
                setIsLoading(false);
                setFormsOfPayment([]);
            }
        } else {
            setIsLoading(false);
        }

    }, []);

    const showAddFormOfPayment = () => {
        setAddFormOfPayment(true);
    }

    const closeAddFormOfPayment = () => {
        setAddFormOfPayment(false);
    }

    const formOfPaymentWasSelected = (fop) => {
        if (fop.id === "Add Form Of Payment" || fop === "Add Form Of Payment" ) {
            showAddFormOfPayment();
        } else {
            selectFormOfPayment(fop);
        }
    }

    const formOfPaymentTypeSelected = (event) => {
        if (selectFormOfPaymentType) {
            selectFormOfPaymentType(event.target.value);
        }
    }

    const newFormOfPaymentAdded = (fop => {
        setAddFormOfPayment(false);
        getFormsOfPayment();
    })

    if (allowInvoice) {
        if (facilityCustomer.allowedToInvoice) {
            if (!formOfPaymentTypes.includes("Invoice")) {
                formOfPaymentTypes.push("Invoice");
            }
        }
    }
    return (
        <Fragment>
            {isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoading &&
                <Stack mt={1} spacing={2}>
                    {
                        formOfPaymentTypes.length > 0 &&
                        <TextField
                            fullWidth
                            id="outlined-select-form-of-payment-type"
                            select
                            label="Form Of Payment Type"
                            onChange={formOfPaymentTypeSelected}
                        >
                            {formOfPaymentTypes.map((fopType) => (
                                <MenuItem key={fopType} value={fopType}>
                                    {fopType}
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    {formOfPaymentType === 'Credit Card' &&
                        <FormOfPaymentsList formOfPayments={formsOfPayment} setFormOfPayment={formOfPaymentWasSelected} selectedFormOfPayment={formOfPayment} mainColor={mainColor} secondaryColor={secondaryColor}></FormOfPaymentsList>
                    }
                </Stack>
            }
            <AddFormOfPayment close={closeAddFormOfPayment} open={!isLoading && addFormOfPayment} facilityCustomer={facilityCustomer} formOfPaymentWasAdded={newFormOfPaymentAdded}></AddFormOfPayment>
        </Fragment>
    )
}

export default FormOfPayment
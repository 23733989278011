import { FacilityReservationUpdateFormOfPaymentContainer, FacilityPayFormContainer } from './facility-reservation-update-form-of-payment.styles';
import FormOfPayment from '../../form-of-payment/form-of-payment.component';
import { useState, useContext } from 'react';
import { updateReservationFormOfPayment } from "../../../utils/facility-api/facility-service";
import LoadingIndicator from '../../loading-indicator/loading-indicator.component';
import { UserContext } from '../../../contexts/user.context';
import MySportSpaceAlert from '../../alert/alert.component';
import { FacilityReservationsContext } from '../../../contexts/facility/facility-reservations.context';
import { Button, Stack, Typography, Box, Modal, Paper } from '@mui/material';
import { getReservation } from '../../../utils/reservation-api/reservation-service';


const FacilityReservationUpdateFormOfPayment = ({ reservation, closeView, isOpen = false }) => {

    const [formOfPaymentType, setFormOfPaymentType] = useState(null);
    const [formOfPayment, setFormOfPayment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const { reservationToView, setReservationToView } = useContext(FacilityReservationsContext);

    const { userData } = useContext(UserContext);

    const selectFormOfPayment = (formOfPayment) => {
        setFormOfPayment(formOfPayment);
    }

    const selectFormOfPaymentType = (type) => {
        setFormOfPaymentType(type);
    }

    const closeThisView = () => {
        setAlertMessage(null);
        setFormOfPaymentType(null);
        setFormOfPayment(null);
        closeView();
    }

    const updateAllReses = () => {
        updateTheFormOfPayment(true);
    }

    const updateThisRes = () => {
        updateTheFormOfPayment(false);
    }

    const updateTheFormOfPayment = async (updateFutureReses) => {
        setIsLoading(true);
        const response = await updateReservationFormOfPayment(reservation, formOfPayment && formOfPayment.id ? formOfPayment.id : formOfPayment ? formOfPayment : null, updateFutureReses);
        if (response.status === 200) {
            if (response.data.status === 'success') {
                let message = "The reservation form of payment has been updated.";
                if (response.data.message) {
                    message = response.data.message;
                }

                const reservationResponse = await getReservation(reservationToView.facilityId, reservationToView.isInternalBooking ? null : reservationToView.customerId, reservationToView.dateKey, reservationToView.reservationId);
                if (reservationResponse && reservationResponse.status === 200 && reservationResponse.data.status === 'success') {
                    setReservationToView(reservationResponse.data.reservation);
                    setAlertMessage(message);
                } else {
                    setAlertMessage("The reservation details were not updated.  Please refresh the page.");
                }
            } else {
                setAlertMessage(response.data.error);
            }

        } else {
            setAlertMessage('The reservation was not paid.  Please try again.');
        }

        setIsLoading(false);
    }

    return (
        <Modal disableScrollLock={true} open={isOpen}>

            <Box sx={{ overflow: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', borderRadius: '5px' }} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'}>
                {isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                }
                {
                    alertMessage &&
                    <MySportSpaceAlert key='pay-failed-res-alert' message={alertMessage} okButtonText={'OK'} okAction={closeThisView}></MySportSpaceAlert>
                }
                <Stack ml={2} mr={2} mb={4} mt={5}>
                    <Typography ml={1} mb={3} fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={"#14254C"} component="div" variant="h6">
                        Update Reservation Form Of Payment
                    </Typography>

                    <Box ml={2} mr={2} mb={2}>
                        <FormOfPayment key={'booking-form-of-payment-type-key'} facilityCustomer={reservation.customer} selectFormOfPayment={selectFormOfPayment} selectFormOfPaymentType={selectFormOfPaymentType} formOfPayment={formOfPayment} formOfPaymentType={formOfPaymentType} allowCashCheck={reservation.isPayingWithCredit ? true : false} ></FormOfPayment>
                    </Box>

                    <Stack mt={2} direction={'row'} spacing={1}>
                        <Button variant='contained' onClick={updateThisRes}>{`This Reservation Only`}</Button>
                        <Button variant='contained' onClick={updateAllReses} >{`All Reservations`}</Button>
                        <Button variant='outlined' onClick={closeThisView} >{`Cancel`}</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}

export default FacilityReservationUpdateFormOfPayment
import { Card, CardContent, Stack, Typography, CardActions, Button, Divider, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';


const FacilityEventForMasterEventCard = ({ eventDetails, backToUrl }) => {

    const { eventType, facilityId, name, gradeStart, gradeEnd, ageRangeBegin, ageRangeEnd, anyAge, id, underOrOverAgeIndicator, underOverAge } = eventDetails;

    const gradeInfo = gradeStart && gradeEnd ? `Grades ${gradeStart} - ${gradeEnd}` : gradeStart ? `Grade ${gradeStart}` : gradeEnd ? `Grade ${gradeEnd}` : null;
    const ageInfo = anyAge ? 'All Ages' : ageRangeBegin && ageRangeEnd ? `Ages ${ageRangeBegin} - ${ageRangeEnd}` : underOrOverAgeIndicator ? `${underOverAge} ${underOrOverAgeIndicator}` : null;

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        goToEventDetails();
    }

    const viewEvent = (e) => {
        e.preventDefault();
        goToEventDetails();
    }

    const goToEventDetails = () => {
        const eventTypeValue = eventType.toUpperCase();
        let eventMainPath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventMainPath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventMainPath = 'tournaments';
                break;
            case 'CAMP':
                eventMainPath = 'camps';
                break;
            default:
                eventMainPath = 'events';
        }

        if (backToUrl) {
            navigate(`/${eventMainPath}/${facilityId}/${id}?redirectUrl=${backToUrl}`);
        } else {
            const path = window.location.pathname;
            navigate(`/${eventMainPath}/${facilityId}/${id}?redirectUrl=${path}`);
        }
    }

    return (
        <Card sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', cursor: "pointer", ":hover": { boxShadow: `0 3px 10px #14254C` } }} onClick={handleClick}>
            {/* <CardContent sx={{ display: "flex", flexDirection: 'column' }}> */}
            <Stack height={'100%'} margin={'1.0vw'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                <Stack height={'90%'} mb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={{ fontSize: '1.25vw' }} gutterBottom textAlign={'center'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div">
                        {name.toUpperCase()}
                    </Typography>
                    {
                        gradeInfo && !gradeInfo.includes("None") &&
                        <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '1.0vw' }} gutterBottom textAlign={'center'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {gradeInfo.toUpperCase()}
                        </Typography>
                    }
                    {
                        ageInfo &&
                        <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '1.0vw' }} gutterBottom textAlign={'center'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {ageInfo.toUpperCase()}
                        </Typography>
                    }
                </Stack>
                <Divider></Divider>
            </Stack>
            {/* </CardContent> */}
            {/* <CardActions> */}
            {/* <Box width={'100%'} display={'flex'} justifyContent={'center'}> */}
            <Button data-id={id} onClick={viewEvent.bind(this)} size="large" color="primary">
                View Details
            </Button>
            {/* </Box> */}

            {/* </CardActions> */}
        </Card>
    )
}

export default FacilityEventForMasterEventCard;
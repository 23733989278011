import { Box, Button, Stack, Typography } from "@mui/material";
import { AdvancedMarker, useAdvancedMarkerRef, InfoWindow, Pin } from "@vis.gl/react-google-maps";
import { useState, useCallback } from 'react';
import logo from '../../../assets/logo-01.png';

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};

const FacilityInfoMarker = ({ position, facilityName, filterType, facilityLogoUrl, viewEvents, facilityId, mainFacilityColor, secondaryFacilityColor, showInfo = false }) => {

    // `markerRef` and `marker` are needed to establish the connection between
    // the marker and infowindow (if you're using the Marker component, you
    // can use the `useMarkerRef` hook instead).
    const [markerRef, marker] = useAdvancedMarkerRef();
    const [infoWindowShown, setInfoWindowShown] = useState(false);

    // clicking the marker will toggle the infowindow
    const handleMarkerClick = useCallback(
        () => setInfoWindowShown(isShown => !isShown),
        []
    );

    // if the maps api closes the infowindow, we have to synchronize our state
    const handleClose = useCallback(() => setInfoWindowShown(false), []);

    const viewFacilityEvents = () => {
        if (viewEvents) {
            viewEvents(facilityId)
        }
    }

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={position}
                onClick={handleMarkerClick}
            >
                <Pin
                    background={mainFacilityColor}
                    borderColor={secondaryFacilityColor}
                    glyphColor={secondaryFacilityColor}
                />
            </AdvancedMarker>

            {infoWindowShown && (
                <InfoWindow anchor={marker} onClose={handleClose}>
                    <Stack overflow={'scroll'} spacing={1} display={'flex'} justifyContent={'center'}>
                        {facilityLogoUrl &&
                            <Box display={'flex'} justifyContent={'center'}>
                                <img style={logoImageStyle} className="facility-event-logo-image" src={facilityLogoUrl ? facilityLogoUrl : logo} alt="Logo" />
                            </Box>
                        }
                        <Typography variant="h6">{facilityName}</Typography>
                        {
                            filterType &&
                            <Button onClick={viewFacilityEvents}>{`View ${filterType}s`}</Button>
                        }

                    </Stack>
                </InfoWindow>
            )}
        </>
    );
}

export default FacilityInfoMarker;
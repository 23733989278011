import { useParams } from 'react-router-dom';
import { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { GetFacilityTourInfo } from '../../utils/facility-api/facility-service';
import mssLogo from "../../assets/logo-01.png";
import SearchForReservation from '../search-for-reservation/search-for-reservation.component';
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import FacilityNotFound from '../facility/facility-not-found/facility-not-found.component';
import MySportSpaceLoadingView from '../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { Box, Typography, Stack, ImageList, ImageListItem, ImageListItemBar, Divider, IconButton, Button } from '@mui/material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import FacilityInfoMarker from '../customer/facility-events/facility-info-marker.component';
import { useNavigate } from "react-router-dom";
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';

const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);

const mainImageStyle = {
    height: '35vh',
    width: '35vw',
    objectFit: 'contain',
    marginTop: '1.0vh'
};

const FacilityInfoView = () => {

    const locationMapRef = useRef(null);
    const contactRef = useRef(null);
    const resSearchRef = useRef(null);
    const eventsRef = useRef(null);
    const { facilityId } = useParams();
    const { isSearchingForReservations } = useContext(SearchForReservationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [facilityTourInfo, setFacilityTourInfo] = useState({});

    const navigate = useNavigate();

    useEffect(() => {

        const fetchFacilityTourInfo = async (facilityId) => {
            setIsLoading(true);

            const response = await GetFacilityTourInfo(facilityId);
            if (response && response.status == 200 && response.data.status === 'success') {
                setFacilityTourInfo(response.data);
            } else {
                setFacilityTourInfo({});
            }

            setIsLoading(false);
        }

        window.scrollTo(0, 0);
        fetchFacilityTourInfo(facilityId);
    }, [facilityId]);
    
    // const allAreaImages = [];
    const areaImagesForDisplay = [];
    const { facilityName, allowWebCalendar, searchTerm, facilityLogoUrl, allowOnlineReservations, facilityPhotoUrl, facilityAreas, promotionalText, hoursOfOperation, address = {}, cancelPolicies = [], facilityAmenities = [], membershipsOnly = false, advanceDaysNoticeForReservations, advanceHoursNoticeForReservations, daysOutForReservations, hasMemberships = false, sportsOffered, maxReservationDate, latitude, longitude, mainFacilityColor = "#14254C", secondaryFacilityColor = '#DEDEDE', phoneNumber, emailAddress } = facilityTourInfo;
    const { streetAddress, state, city, zipCode } = address;
    if (facilityTourInfo && facilityAreas) {
        for (const area of facilityAreas) {
            if (area.imageLocationURL) {
                // allAreaImages.push({
                //     imageUrl: area.imageLocationURL,
                //     id: area.areaId,
                //     name: area.name,
                //     sortOrder: area.sortOrder
                // });

                areaImagesForDisplay.push({
                    imageUrl: area.imageLocationURL,
                    id: area.areaId,
                    name: area.name,
                    sortOrder: area.sortOrder
                });

                if (area.subAreas) {
                    for (const sArea of area.subAreas) {
                        if (sArea.imageLocationURL) {
                            areaImagesForDisplay.push({
                                imageUrl: sArea.imageLocationURL,
                                id: sArea.areaId,
                                name: sArea.name,
                                sortOrder: sArea.sortOrder
                            });
                        }
                    }
                }
            }
        }

        areaImagesForDisplay.sort((area1, area2) => {
            if (area1.sortOrder < area2.sortOrder) {
                return -1;
            }

            if (area1.sortOrder > area2.sortOrder) {
                return 1;
            }

            return 0;
        });
    }

    const goToWebCalendar = () => {
        var calendarId = facilityId;
        if (searchTerm) {
            calendarId = searchTerm;
        }

        navigate(`/reservations/${calendarId}`);
    }

    const goToTournaments = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/tournaments/${path}`);
    }

    const goToCamps = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/camps/${path}`);
    }

    const goToLeagues = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/leagues/${path}`);
    }

    const goToBirthdayParties = () => {
        var path = facilityId;
        if (searchTerm) {
            path = searchTerm;
        }
        navigate(`/birthday-parties/${path}`);
    }

    return (
        <Box mt={11} mb={11} ml={10} mr={10}>
            <MySportSpaceLoadingView isOpen={isSearchingForReservations || isLoading}></MySportSpaceLoadingView>
            {
                !isLoading && !facilityName &&
                <FacilityNotFound></FacilityNotFound>
            }
            {
                !isLoading && facilityName &&
                <Stack display={'flex'} alignContent={'center'} alignItems={'center'} spacing={3}>
                    <Stack ml={4} width={'100%'} spacing={5} direction={'row'} display={'flex'} alignItems={'stretch'}>
                        <Typography width={'80%'} fontWeight={'bold'} ml={10} mt={2} mb={2} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h4">
                            {facilityName}
                        </Typography>
                        <Stack width={'20%'} spacing={2} direction={'row'} display={'flex'} justifyContent={'flex-end'}>
                            <Stack>
                                <IconButton sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }} aria-label="contact" onClick={() => contactRef.current?.scrollIntoView()}>
                                    <EmailOutlinedIcon />
                                </IconButton>
                                <Button size='small' nClick={() => contactRef.current?.scrollIntoView()} sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }}>Contact</Button>
                            </Stack>
                            <Stack>
                                <IconButton sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }} aria-label="map-icon" onClick={() => locationMapRef.current?.scrollIntoView()}>
                                    <MapOutlinedIcon />
                                </IconButton>
                                <Button size='small' onClick={() => locationMapRef.current?.scrollIntoView()} sx={{
                                    ':hover': {
                                        bgcolor: 'white'
                                    }
                                }}>Location</Button>
                            </Stack>
                            {
                                allowOnlineReservations &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="res-search-icon" onClick={() => eventsRef.current?.scrollIntoView()}>
                                        <EmojiEventsOutlinedIcon />
                                    </IconButton>
                                    <Button size='small' onClick={() => eventsRef.current?.scrollIntoView()} sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Events</Button>
                                </Stack>
                            }
                            {
                                allowOnlineReservations &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="res-search-icon" onClick={() => resSearchRef.current?.scrollIntoView()}>
                                        <SearchOutlinedIcon />
                                    </IconButton>
                                    <Button size='small' onClick={() => resSearchRef.current?.scrollIntoView()} sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Search</Button>
                                </Stack>
                            }
                            {
                                allowWebCalendar &&
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="calendar" onClick={goToWebCalendar}>
                                        <CalendarMonthOutlinedIcon />
                                    </IconButton>
                                    <Button size='small' onClick={goToWebCalendar} sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Calendar</Button>
                                </Stack>
                            }

                        </Stack>
                    </Stack>
                    <Divider sx={{ width: '100%' }} />
                    <Box flexDirection={'column'} display={'flex'} justifyContent={'flex-start'} alignItems={'stretch'} >
                        {/* <Stack width={'100%'} spacing={5} mt={2} ml={3} mr={1} justifyContent={'space-evenly'} alignContent={'center'} alignItems={'center'} > */}

                        <Stack direction={'row'} mb={2} spacing={3} display={'flex'} alignContent={'center'} alignItems={'center'}>
                            <img style={mainImageStyle} className="facility-logo-image" src={facilityPhotoUrl ? facilityPhotoUrl : facilityLogoUrl ? facilityLogoUrl : mssLogo} alt="Logo" loading="lazy" />
                            <ImageList sx={{ width: '35vw', height: '35vh' }} cols={3} >
                                {
                                    areaImagesForDisplay.map((image) => {
                                        return <ImageListItem key={image.id}>
                                            <img
                                                srcSet={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                src={`${image.imageUrl}&w=164&h=164&fit=crop&auto=format`}
                                                alt={image.name}
                                                loading='lazy'
                                            />
                                            <ImageListItemBar
                                                title={image.name} />
                                        </ImageListItem>
                                    })
                                }
                            </ImageList>
                        </Stack>
                        {/* </Stack> */}
                    </Box>
                    <Divider sx={{ width: '100%' }} />
                    <Stack ml={1} mr={5} sx={{ width: '100%' }} direction={'row'}>
                        <Stack width={'100%'}>
                            {
                                promotionalText &&
                                <Fragment>
                                    <Typography fontWeight={'bold'} ml={5} mt={2} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {promotionalText}
                                    </Typography>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                </Fragment>
                            }

                            {
                                allowOnlineReservations &&
                                <section ref={resSearchRef} id="res-search-section">
                                    <Stack sx={{ maxHeight: '50vh' }} mt={1} mb={1} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} alignContent={'center'}>
                                        <Typography width={'100%'} fontWeight={'bold'} ml={10} mt={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                            Search For Reservation
                                        </Typography>
                                        <Box ml={6} width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                                            <SearchForReservation key={'search-for-reservation-component'} facilityId={facilityId} searchFormDirection='row' sportsOverride={sportsOffered} backUrl={`/facilityinfo/${facilityId}`} showTitle={false}></SearchForReservation>
                                        </Box>
                                    </Stack>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                </section>
                            }
                            <Stack>
                                <section ref={eventsRef} id="res-search-section">
                                    {/* <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Events
                                    </Typography> */}
                                    <Stack mt={2} spacing={2} ml={7} direction={'row'} display={'flex'} justifyContent={'flex-start'}>
                                        <Stack sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                            <IconButton size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }} aria-label="tournaments" onClick={goToBirthdayParties} >
                                                <CelebrationOutlinedIcon />
                                            </IconButton>
                                            <Button onClick={goToBirthdayParties} size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }}>Birthday Parties</Button>
                                        </Stack>
                                        <Stack sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                            <IconButton size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }} aria-label="tournaments" onClick={goToTournaments} >
                                                <FormatListNumberedOutlinedIcon />
                                            </IconButton>
                                            <Button onClick={goToTournaments} size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }}>Tournaments</Button>
                                        </Stack>
                                        <Stack mt={1} sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                            <IconButton size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }} aria-label="camps" onClick={goToCamps} >
                                                <TimerOutlinedIcon />
                                            </IconButton>
                                            <Button onClick={goToCamps} size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }}>Camps</Button>
                                        </Stack>
                                        <Stack mt={1} sx={{ minWidth: '10vw', borderRadius: '5px', boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)' }}>
                                            <IconButton size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }} aria-label="leagues" onClick={goToLeagues} >
                                                <FeaturedPlayListIcon />
                                            </IconButton>
                                            <Button onClick={goToLeagues} size='large' sx={{
                                                ':hover': {
                                                    bgcolor: 'white'
                                                }
                                            }}>Leagues</Button>
                                        </Stack>
                                    </Stack>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                </section>
                            </Stack>

                            {hoursOfOperation &&
                                <Fragment>
                                    <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Hours Of Operation
                                    </Typography>
                                    {
                                        hoursOfOperation.map((hop) => {
                                            return <Stack ml={8} display={'flex'} justifyContent={'center'} alignItems={'center'} alignContent={'center'} spacing={2} width={'80%'} direction={'row'} key={hop.dayText} >
                                                <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                    {hop.dayText}
                                                </Typography>
                                                <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                    {hop.startTime}
                                                </Typography>
                                                <Typography width={'33%'} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                                    {hop.endTime}
                                                </Typography>
                                            </Stack>
                                        })
                                    }
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                                </Fragment>
                            }


                            <Stack spacing={1} mr={8} direction={'row'}>
                                <Stack width={'50%'}>
                                    <Typography fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Facility Details
                                    </Typography>
                                    <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {membershipsOnly ? "Members Only Facility" : "Retail Facility"}
                                    </Typography>

                                    {
                                        hasMemberships &&
                                        <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            Memberships are available
                                        </Typography>
                                    }
                                    <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                        {advanceDaysNoticeForReservations && advanceDaysNoticeForReservations > 0 ? `Reservations require ${advanceDaysNoticeForReservations} days advance notice` : advanceHoursNoticeForReservations && advanceHoursNoticeForReservations > 0 ? `Reservations require ${advanceHoursNoticeForReservations} hours notice` : "No advance notice required"}
                                    </Typography>

                                    {
                                        daysOutForReservations && !maxReservationDate &&
                                        <Typography ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            {
                                                `Reservations bookings are allowed up to ${moment().add(daysOutForReservations, 'days').format("ddd, MMMM D, YYYY")}`
                                            }
                                        </Typography>
                                    }

                                    {
                                        maxReservationDate &&
                                        <Typography noWrap={false} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                            {
                                                `Reservations bookings are allowed up to ${moment(maxReservationDate, 'YYYY-MM-DD').format("ddd, MMMM D, YYYY")}`
                                            }
                                        </Typography>
                                    }
                                </Stack>
                                <Stack>
                                    {
                                        facilityAmenities &&
                                        <Fragment>
                                            <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                                Facility Includes
                                            </Typography>
                                        </Fragment>
                                    }
                                    {
                                        facilityAmenities && facilityAmenities.map((amenenty) => {
                                            return <Typography key={amenenty} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{amenenty}</Typography>
                                        })
                                    }
                                </Stack>
                            </Stack>
                            {
                                cancelPolicies &&
                                <Fragment>
                                    <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>

                                    <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={2} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                        Cancellation Policies
                                    </Typography>
                                </Fragment>
                            }
                            {
                                cancelPolicies && cancelPolicies.map((policy) => {
                                    return <Typography key={policy} ml={8} textAlign={'left'} color={"#14254C"} component="div" variant="body1">{policy}</Typography>
                                })
                            }
                        </Stack>
                    </Stack>

                    <Stack width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                        <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        <section ref={contactRef} id="location-map-section">
                            <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                                Contact {facilityName}
                            </Typography>
                        </section>
                        <Typography width={'100%'} ml={7} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {emailAddress}
                        </Typography>
                        <Typography width={'100%'} ml={7} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {phoneNumber}
                        </Typography>
                    </Stack>

                    <Stack width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                        <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                        <Typography width={'100%'} fontWeight={'bold'} ml={5} mt={2} mb={1} textAlign={'left'} color={"#A9A9A9"} component="div" variant="h6">
                            Facility Location
                        </Typography>
                        <Typography width={'100%'} ml={7} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {streetAddress}
                        </Typography>
                        <Typography width={'100%'} ml={7} mt={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                            {city}, {state} {zipCode}
                        </Typography>
                        <section ref={locationMapRef} id="location-map-section">
                            <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
                                <Box mt={1} display={'flex'} justifyContent='center'>
                                    <Box sx={{ height: '50vh', width: '77vw', backgroundColor: 'gray' }} >
                                        <Map mapId={'bf51a910020fa25a'} defaultCenter={{ lat: latitude, lng: longitude }} zoom={15} disableDefaultUI>
                                            <FacilityInfoMarker showInfo={false} key={facilityId} position={{ lat: latitude, lng: longitude }} facilityName={facilityName} facilityLogoUrl={facilityLogoUrl} mainFacilityColor={mainFacilityColor} secondaryFacilityColor={secondaryFacilityColor}></FacilityInfoMarker>
                                        </Map>
                                    </Box>
                                </Box>
                            </APIProvider>
                        </section>
                    </Stack>
                </Stack>

            }
        </Box >
    )

}

export default FacilityInfoView;
import { Routes, Route } from 'react-router-dom';
import CustomerFacilityCalendar from '../../components/customer/customer-facility-calendar/customer-facility-calendar.component';
import { CalendarSettingsProvider } from "../../contexts/customer/calendar-settings.context";
import SearchForReservationResults from "../../components/search-for-reservation/search-for-reservation-results/search-for-reservations-results.component";

const Reservations = () => {

    return (
        <CalendarSettingsProvider>
                <Routes>
                    <Route path=":facilityName" element={<CustomerFacilityCalendar />} />
                    <Route path=":facilityName/:urlCalendarDate" element={<CustomerFacilityCalendar />} />
                    <Route path="search-results" element={<SearchForReservationResults></SearchForReservationResults>} />
                </Routes>
        </CalendarSettingsProvider>
    )
}

export default Reservations;
import { CustomerFormOfPaymentContainer } from './customer-form-of-payments.styles';
import { UserContext } from '../../../contexts/user.context';
import { useContext, useState, useEffect } from 'react';
import { getPaymentMethods } from '../../../utils/customer-api/customer-service';
import CreditCardForm from '../../credit-card-form/credit-card-form.component';
import { addFormOfPayment } from '../../../utils/customer-api/customer-service';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import { Stack, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';


const CustomerFormOfPayments = ({ selectFormOfPayment, showLoadingIndicator = false }) => {

    const [formsOfPayment, setFormsOfPayment] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useContext(UserContext);
    const [formOfPaymentSelected, setFormOfPaymentSelected] = useState(null);
    const [showInputForm, setShowInputForm] = useState(false);

    useEffect(() => {
        async function getFormsOfPayment() {
            setIsLoading(true);

            if (userData) {
                const formsOfPaymentResponse = await getPaymentMethods(userData.stripeCustomerId);
                let formsOfPayment = [];
                if (formsOfPaymentResponse.status === 200) {
                    formsOfPayment = formsOfPaymentResponse.data.data;
                }
                setFormsOfPayment(formsOfPayment);

            }

            setIsLoading(false);
        }
        getFormsOfPayment();
    }, [userData]);

    const fopSelected = (e) => {
        const formOfPaymentId = e.currentTarget.dataset.id === formOfPaymentSelected ? null : e.currentTarget.dataset.id;
        setFormOfPaymentSelected(formOfPaymentId);

        if (selectFormOfPayment) {
            selectFormOfPayment(formOfPaymentId);
        }
    }

    const toggleShowInputForm = () => {
        if (selectFormOfPayment) {
            selectFormOfPayment(null);
        }
        setFormOfPaymentSelected(null)
        setShowInputForm(!showInputForm)
    }

    const cancelAddFormOfPayment = () => {
        setShowInputForm(false)
    }

    const addNewFormOfPayment = async (formOfPayment) => {
        setIsLoading(true);
        const cardDetails = {
            creditCardNumber: formOfPayment.number,
            expMonth: formOfPayment.expiry.substring(0, 2),
            expYear: formOfPayment.expiry.substring(2),
            zipCode: formOfPayment.zipCode
        }

        var firstName = userData.userFirstName;
        var lastName = userData.userFirstName;
        const endOfFirstIndex = formOfPayment.name.indexOf(" ");
        if (endOfFirstIndex >= 0) {
            firstName = formOfPayment.name.substring(0, endOfFirstIndex);
            lastName = formOfPayment.name.substring(endOfFirstIndex + 1);
        }
        const customer = {
            stripeCustomerId: userData.stripeCustomerId,
            userEmailAddress: userData.userEmailAddress,
            userPhoneNumber: userData.userPhoneNumber,
            userFirstName: firstName,
            userLastName: lastName,
            contactInfo: {
                billingAddress1: formOfPayment.address1,
                billingAddress2: formOfPayment.address2,
                billingState: formOfPayment.state,
                billingCity: formOfPayment.city,
            }
        }
        const response = await addFormOfPayment('card', cardDetails, customer);
        if (response.status === 200) {
            if (response.data.id && response.data.card) {
                alert("The form of payment has been added.");
                setShowInputForm(false);
                setFormsOfPayment(Array.from(new Set([{ id: response.data.id, card: response.data.card }].concat(formsOfPayment))))
                setFormOfPaymentSelected(response.data.id);
                if (selectFormOfPayment) {
                    selectFormOfPayment(response.data.id);
                }
            } else {
                if (response.data.raw && response.data.raw.message) {
                    alert(response.data.raw.message);
                } else {
                    alert("There was an issue adding this form of payment.  The form of payment could not be added.");
                }
            }
        } else {
            alert("The form of payment was not added.");
        }

        setIsLoading(false);
    }


    return (
        <CustomerFormOfPaymentContainer>
            {
                !showLoadingIndicator &&
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            }
            {
                showLoadingIndicator && isLoading &&
                <Box display={'flex'} justifyContent={'center'}>
                    <CircularProgress sx={{ color: '#14254C' }} />
                </Box>

            }
            {
                !isLoading &&
                <Stack>
                    <Box ml={1} mt={1} display="flex"
                        justifyContent="flex-start"
                        alignItems="flex-start">
                        <Button onClick={toggleShowInputForm} startIcon={<AddIcon />}>
                            Add Form Of Payment
                        </Button>
                    </Box>
                    <List>
                        {
                            showInputForm &&
                            <div className='full-screen-for-pop-up-container'>
                                <div className="display-popup-containter">
                                    <div className='add-payment-info-container'>
                                        <CreditCardForm addFormOfPayment={addNewFormOfPayment} cancel={cancelAddFormOfPayment}></CreditCardForm>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            formsOfPayment.map((fop) => {
                                const { card = {} } = fop;
                                const { brand = "N/A", last4 = "N/A", exp_month = "NA", exp_year = "NA" } = card;
                                return <ListItem key={fop.id} divider={true} onClick={fopSelected.bind(this)} data-id={fop.id} >
                                    <ListItemText primary={`${brand.toUpperCase()} - *${last4} - Expires ${exp_month}/${exp_year}`}></ListItemText>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={formOfPaymentSelected === fop.id}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                </ListItem>
                            })

                        }
                    </List>
                </Stack>
            }
        </CustomerFormOfPaymentContainer>
    )

}

export default CustomerFormOfPayments;
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import RememberMeOutlinedIcon from '@mui/icons-material/RememberMeOutlined';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import { Tabs, Tab, Box } from "@mui/material";
import { useState, useEffect } from "react";

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const tabStyle = {
    fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '5.25vw' } 
};

const HomePageTabBar = ({ tabBarSelected, selectedTab = 'facilities' }) => {

    const [value, setValue] = useState(selectedTab);

    const selectTab = (event, newValue) => {
        setValue(newValue);
        tabBarSelected(newValue);
    }

    const initTabSelected = (tab) => {
        setValue(tab);
    }

    useEffect(() => {
        initTabSelected(selectedTab);
    }, [selectedTab]);

    return (
        <Box ml={2} mr={2}>
            <Tabs variant="fullWidth" value={value} onChange={selectTab} aria-label="home-page-tabs">
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }}  value="facilities" icon={<PinDropOutlinedIcon sx={{fontSize: '1.75vw'}} />} label="Facilities" {...a11yProps(0)} />
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} value="birthdayparties" icon={<CelebrationOutlinedIcon sx={{fontSize: '1.75vw'}} />} label="Birthday Parties" {...a11yProps(1)} />
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }}  value="memberships" icon={<RememberMeOutlinedIcon sx={{fontSize: '1.75vw'}}/>} label="Memberships" {...a11yProps(2)} />
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }}  value="tournaments" icon={<FormatListNumberedOutlinedIcon sx={{fontSize: '1.75vw'}} />} label="Tournaments" {...a11yProps(3)} />
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} value="camps" icon={<TimerOutlinedIcon sx={{fontSize: '1.75vw'}} />} label="Camps" {...a11yProps(4)} />
                <Tab sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} value="leagues" icon={<FeaturedPlayListIcon sx={{fontSize: '1.75vw'}}/>} label="Leagues" {...a11yProps(5)} />
            </Tabs>
        </Box>
    )
}

export default HomePageTabBar
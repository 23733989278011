import logo from '../../assets/logo-01.png';
import { Box, Stack, AppBar, Link, Button, Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const NavigationSignedout = () => {

    const navigate = useNavigate();

    const getTheApp = () => {
        navigate('/mobile-app');
    }

    return (
        <AppBar component={'nav'} sx={{ backgroundColor: 'white', maxHeight: '9.0vh' }} position='fixed'>
            <Stack ml={4} mr={4}>
                <Box display="flex" justifyContent="center" alignContent={'center'} alignItems="center">
                    <Link sx={{ fontWeight: 'bold', color: '#14254C', fontSize: { xs: '1.0vh', sm: '1.75vh', md: '1.0vh', lg: '1.5vh', xl: '1.5vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info">Learn more about My Sport Space</Link>
                </Box>
                <Grid ml={1} mr={1} direction={'row'} container>
                    <Grid item xs={3}>
                        <Link className="logo-container" href='/'>
                            <Box sx={{ height: { xs: '3.0vh', sm: '3.0vh', md: '3.0vh', lg: '5.0vh', xl: '4.0vh' } }} display={'flex'}>
                                <img className="logo-image" src={logo} alt="Logo" />
                            </Box>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="center" alignContent={'stretch'} alignItems="stretch">
                            <Button sx={{ fontFamily: 'Helvetica', fontWeight: 'bold', fontSize: { xs: '1.25vh', sm: '1.1vh', md: '1.25vh', lg: '1.5vh', xl: '1.5vh' }, borderRadius: '5px' }} onClick={getTheApp} variant='outlined' >Get The App</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                            <Stack mb={1} alignItems={'center'} alignContent={'center'} spacing={2} direction={'row'}>
                                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.0vh', lg: '1.5vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info/how-it-works.html">Help</Link>
                                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.0vh', lg: '1.5vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info/contact.html">Contact</Link>
                                <Link sx={{ fontWeight: 'bold', color: 'dark-gray', fontSize: { xs: '1.0vh', sm: '1.0vh', lg: '1.5vh' } }} underline="none" variant="subtitle1" href="https://www.mysportspace.info/facility-infodemo.html">Schedule a Facility Demo</Link>
                                <Link sx={{ color: '#14254C', fontWeight: 'bold', fontSize: { xs: '1.0vh', sm: '1.0vh', md: '1.0vh', lg: '1.5vh' } }} underline="none" href="/auth">
                                    Sign In
                                </Link>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </AppBar>
    )
}

export default NavigationSignedout
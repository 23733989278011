import { Modal, Paper, Stack, Box, Typography, IconButton, Button, Divider } from "@mui/material";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { useState, useEffect, useRef, useContext, Fragment } from "react";
import { GetFacilityEventDetails } from "../../../utils/facility-events/facility-events-service";
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import mssLogo from "../../../assets/logo-01.png";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FacilityInfoMarker from "./facility-info-marker.component";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FacilityEventSignup from "./facility-event-signup.component";
import { UserContext } from "../../../contexts/user.context";
import MySportSpaceAlert from "../../alert/alert.component";
import { useParams, useSearchParams } from 'react-router-dom';
import FacilityEventForMasterEventCard from "./facility-event-for-master-event-card.component";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const mainImageStyle = {
    height: '25vh',
    width: '25vw',
    objectFit: 'contain',
    marginTop: '1.0vh'
};

const FacilityEventDetail = ({ backToUrl }) => {

    const { facilityEventId, facilityId } = useParams();
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const locationMapRef = useRef(null);
    const contactRef = useRef(null);
    const [openSignUp, setOpenSignup] = useState(false);
    const { userData } = useContext(UserContext);
    const [signInAlertMessage, setSignInAlertMessage] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {

        const fetchFacilityEventDetails = async (eventId) => {
            if (eventId) {
                setIsLoading(true);
                const response = await GetFacilityEventDetails(eventId, userData ? userData.userId : null, facilityId);
                if (response && response.status == 200 && response.data.status === 'success') {
                    setEventDetails(response.data.eventDetails);
                } else {
                    setEventDetails(null);
                }
                setIsLoading(false);
            }
        }

        fetchFacilityEventDetails(facilityEventId);
        return () => {
            setEventDetails(null);
        }

    }, [facilityEventId]);

    const signupForEvent = (event) => {
        if (userData) {
            setOpenSignup(true);
        } else {
            setSignInAlertMessage({ message: `You must be signed in to sign up for a ${eventDetails.eventType}.` });
        }
    }

    const closeSignup = () => {
        setOpenSignup(false);
    }

    const closeView = () => {

        if (backToUrl) {
            navigate(backToUrl);
            return;
        }
        const redirectUrl = searchParams.get('redirectUrl');
        if (redirectUrl) {
            navigate(redirectUrl);
        } else {

            const eventTypeValue = eventDetails.eventType.toUpperCase();
            let selectTab = '';
            switch (eventTypeValue) {
                case 'LEAGUE':
                    selectTab = 'leagues';
                    break;
                case 'TOURNAMENT':
                    selectTab = 'tournaments';
                    break;
                case 'CAMP':
                    selectTab = 'camps';
                    break;
                default:
                    selectTab = 'events';
            }

            navigate(`/?selectTab=${selectTab}`);
        }
    }

    const closeSignInAlert = () => {
        setSignInAlertMessage(null);
    }

    const getEventRedirect = () => {
        const eventTypeValue = eventDetails.eventType.toUpperCase();
        let eventTypePath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventTypePath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventTypePath = 'tournaments';
                break;
            case 'CAMP':
                eventTypePath = 'camps';
                break;
            default:
                eventTypePath = 'events';
        }

        return `${eventTypePath}/${eventDetails.facilityId}/${facilityEventId}`;
    }

    const signIn = () => {

        const redirectUrl = getEventRedirect();
        navigate(`/auth?redirectUrl=/${redirectUrl}`);
    }

    const viewDocument = () => {
        window.location.href = eventDetails.documentUrl;
    }

    const locationAddress = eventDetails && eventDetails.address ? `${eventDetails.address}, ${eventDetails.city} ${eventDetails.state} ${eventDetails.zipCode}` : null;
    const gradeInfo = eventDetails && eventDetails.gradeStart && eventDetails && eventDetails.gradeEnd ? `Grades ${eventDetails.gradeStart} - ${eventDetails.gradeEnd}` : eventDetails && eventDetails.gradeStart ? `Grade ${eventDetails.gradeStart}` : eventDetails && eventDetails.gradeEnd ? `Grade ${eventDetails.gradeEnd}` : null;
    const overUnderAgeInfo = eventDetails && eventDetails.anyAge ? 'All Ages' : eventDetails && eventDetails.underOrOverAgeIndicator && eventDetails.underOverAge ? `${eventDetails.underOverAge} ${eventDetails.underOrOverAgeIndicator}` : null;
    const ageRangeInfo = eventDetails && eventDetails.ageRangeBegin && eventDetails.ageRangeBegin ? `Ages ${eventDetails.ageRangeBegin} - ${eventDetails.ageRangeEnd}` : null;
    const facilityAddress = eventDetails && eventDetails.facilityAddress ? `${eventDetails.facilityAddress.address}, ${eventDetails.facilityAddress.city} ${eventDetails.facilityAddress.state} ${eventDetails.facilityAddress.zipCode}` : ''
    const eventStartDate = eventDetails && eventDetails.eventStartsDate ? dayjs(eventDetails.eventStartsDate, 'YYYY-MM-DD').format('dddd, MMM YYYY').toUpperCase() : null;
    const eventEndDate = eventDetails && eventDetails.eventEndsDate ? dayjs(eventDetails.eventEndsDate, 'YYYY-MM-DD').format('dddd, MMM YYYY').toUpperCase() : null;
    const eventDate = eventStartDate && eventEndDate ? `${eventStartDate} - ${eventEndDate}` : eventStartDate ? eventStartDate : eventEndDate ? eventEndDate : '';
    const timeInfo = eventDetails && eventDetails.timeStart && eventDetails.timeEnd ? `${eventDetails.timeStart} - ${eventDetails.timeEnd}` : null;
    const signupStartDate = eventDetails && eventDetails.signupStartsDate ? dayjs(eventDetails.signupStartsDate, 'YYYY-MM-DD').format('dddd, MMM D YYYY') : null;
    const signupEndDate = eventDetails && eventDetails.signupEndsDate ? dayjs(eventDetails.signupEndsDate, 'YYYY-MM-DD').format('dddd, MMM D YYYY') : null;
    const signupsExpired = eventDetails ? dayjs.tz(dayjs(), dayjs.tz.guess()).isSameOrAfter(dayjs(eventDetails.signupEndsDate, 'YYYY-MM-DD')) : false;
    const signupsHaveStarted = eventDetails ? dayjs.tz(dayjs(), dayjs.tz.guess()).isSameOrAfter(dayjs(eventDetails.signupStartsDate, 'YYYY-MM-DD')) : false;

    return (
        <Box sx={{ overflow: 'scroll' }} position={'fixed'} top={'9.0vh'} left={'0%'} right={'0%'} bottom={'5%'} display={'flex'}>
            <Stack ml={1} mr={1} mb={5} sx={{ width: '100%' }} display={"flex"} alignItems={'center'} >
                <MySportSpaceAlert key={'facility-event-sign-in-alert'} isOpen={signInAlertMessage ? true : false} message={signInAlertMessage ? signInAlertMessage.message : ""} okButtonText={'Sign In'} okAction={signIn} cancelAction={closeSignInAlert} cancelButtonText={"Cancel"} ></MySportSpaceAlert>
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                < Modal sx={{ overflow: 'auto', overflowY: 'scroll', position: 'absolute', top: '10%', bottom: '1%', left: '5%', right: '5%' }} open={openSignUp}>
                    <Paper sx={{ overflow: 'scroll' }}>
                        <FacilityEventSignup facilityEvent={eventDetails} close={closeSignup}></FacilityEventSignup>
                    </Paper>
                </Modal >
                {
                    eventDetails &&
                    <Stack width={'90vw'} spacing={4} mt={1} ml={1} mr={1} alignContent={'center'} alignItems={'center'} >
                        <Stack width={'100%'} spacing={5} direction={'row'} display={'flex'}>
                            <Box width={'10vw'} display={'flex'} justifyContent={'flex-start'} alignContent={'flex-start'} alignItems={'flex-start'}>
                                <Button onClick={closeView} startIcon={<ArrowBackIcon />}>
                                    Back
                                </Button>
                            </Box>
                            <Box width={'60vw'} display={'flex'} justifyContent={'center'}>
                                <img style={mainImageStyle} className="facility-logo-image" src={eventDetails && eventDetails.imageUrl ? eventDetails.imageUrl : eventDetails.facilityLogoUrl ? eventDetails.facilityLogoUrl : mssLogo} alt="Logo" loading="lazy" />
                            </Box>
                            <Stack width={'10vw'} spacing={2} direction={'row'} display={'flex'} justifyContent={'flex-end'} alignContent={'center'} alignItems={'center'}>
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="contact" onClick={() => contactRef.current?.scrollIntoView()}>
                                        <EmailOutlinedIcon />
                                    </IconButton>
                                    <Button size='small' sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Contact</Button>
                                </Stack>
                                <Stack>
                                    <IconButton sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }} aria-label="map-icon" onClick={() => locationMapRef.current?.scrollIntoView()}>
                                        <MapOutlinedIcon />
                                    </IconButton>
                                    <Button size='small' sx={{
                                        ':hover': {
                                            bgcolor: 'white'
                                        }
                                    }}>Location</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider sx={{ width: '90vw' }}></Divider>
                        <Stack width={'98%'} display='flex'>
                            <Typography fontWeight={'bold'} ml={1} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                {eventDetails ? eventDetails.masterEventId : ''}
                            </Typography>
                            <Typography fontWeight={'bold'} ml={2} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                {eventDetails ? eventDetails.name : ''}
                            </Typography>
                        </Stack>
                        <Divider sx={{ width: '90vw' }}></Divider>
                        <Stack width={'98%'} spacing={2}>
                            <Stack spacing={1} direction={'row'}>
                                <LocationOnOutlinedIcon sx={{ marginLeft: '2.0vw' }}  ></LocationOnOutlinedIcon>
                                <Stack >
                                    <Typography width={'80vw'} fontWeight={'bold'} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                        {locationAddress ? locationAddress.toUpperCase() : facilityAddress ? facilityAddress.toUpperCase() : ''}
                                    </Typography>
                                    <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                        {eventDetails && eventDetails.locationName ? eventDetails.locationName : eventDetails ? eventDetails.facilityName : ''}
                                    </Typography>
                                </Stack>
                            </Stack>
                            {!eventDetails.isMasterEvent &&
                                <Stack spacing={2} direction={'row'}>
                                    <CalendarMonthOutlinedIcon sx={{ marginLeft: '2.0vw' }}  ></CalendarMonthOutlinedIcon>
                                    <Stack >
                                        <Typography width={'80vw'} fontWeight={'bold'} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                            {eventDate.toUpperCase()}
                                        </Typography>
                                        <Typography width={'80vw'} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                            Registration {signupStartDate} - {signupEndDate}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }
                            {!eventDetails.isMasterEvent &&
                                <Stack spacing={2} direction={'row'}>
                                    <PersonOutlineOutlinedIcon sx={{ marginLeft: '2.0vw' }}  ></PersonOutlineOutlinedIcon>
                                    <Stack >
                                        <Typography width={'80vw'} fontWeight={'bold'} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                            {gradeInfo && !gradeInfo.includes("None") ? gradeInfo.toUpperCase() : ageRangeInfo ? ageRangeInfo.toUpperCase() : overUnderAgeInfo ? overUnderAgeInfo.toUpperCase() : 'Contact Facility For Age/Grade Info'.toUpperCase()}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }

                            {
                                !eventDetails.isMasterEvent && timeInfo &&
                                <Stack spacing={2} direction={'row'}>
                                    <AccessTimeOutlinedIcon sx={{ marginLeft: '2.0vw' }}  ></AccessTimeOutlinedIcon>
                                    <Stack >
                                        <Typography fontWeight={'bold'} textAlign={'left'} color={"#14254C"} component="div" variant="subtitle1">
                                            {timeInfo.toUpperCase()}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            }
                        </Stack>
                        <Divider sx={{ width: '90vw' }}></Divider>
                        <Typography width={'98%'} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                            {eventDetails ? eventDetails.promotionalText : ''}
                        </Typography>
                        {
                            eventDetails && eventDetails.specialInstructions &&
                            <Fragment>
                                <Divider sx={{ width: '90vw' }}></Divider>
                                <Stack width={'98%'} spacing={2}>
                                    <Typography textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                        {eventDetails.specialInstructions}
                                    </Typography>
                                </Stack>
                            </Fragment>
                        }
                        {
                            eventDetails && eventDetails.additionalInfo &&
                            <Fragment>
                                <Divider sx={{ width: '90vw' }}></Divider>
                                <Stack width={'98%'} display={'flex'}>
                                    {
                                        eventDetails.additionalInfo.map((info) => {
                                            return <Typography key={info} textAlign={'left'} color={"14254C"} component="div" variant="subtitle1">
                                                {info}
                                            </Typography>
                                        })
                                    }
                                </Stack>
                            </Fragment>
                        }
                        <Divider sx={{ width: '90vw' }}></Divider>
                        {
                            !eventDetails.isMasterEvent &&
                            <Stack width={'98%'} display={'flex'}>
                                <Typography width={'100%'} fontWeight={'bold'} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                    Sign Up For {eventDetails.name}
                                </Typography>
                                {
                                    !signupsExpired && signupsHaveStarted &&
                                    <Button sx={{ width: '20vw', marginTop: '2.0vh', marginLeft: '1.0vw' }} onClick={signupForEvent} variant="contained">Sign Up</Button>
                                }
                                {
                                    signupsExpired &&
                                    <Typography ml={2} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                        Registration has closed for this event
                                    </Typography>
                                }
                                {
                                    !signupsHaveStarted && !signupsExpired &&
                                    <Typography ml={2} textAlign={'left'} color={"gray"} component="div" variant="subtitle1">
                                        Registration starts on {signupStartDate}
                                    </Typography>
                                }
                            </Stack>
                        }
                        {
                            eventDetails.isMasterEvent &&
                            <Stack width={'98%'} display={'flex'}>
                                <Typography fontWeight={'bold'} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                    Sign Ups
                                </Typography>
                            </Stack>
                        }
                        {
                            eventDetails.isMasterEvent &&
                            <Stack spacing={1} width={'98%'} display={'flex'} justifyContent={'space-evenly'} flexWrap={'wrap'} columnGap={1} rowGap={1} direction={'row'}>
                                {eventDetails.allEvents &&
                                    eventDetails.allEvents.map((event) => {
                                        return <FacilityEventForMasterEventCard backToUrl={getEventRedirect()} key={event.id} eventDetails={event}></FacilityEventForMasterEventCard>
                                    })
                                }
                            </Stack>
                        }
                        {
                            eventDetails.documentUrl &&
                            <Fragment>
                                <Divider sx={{ width: '90vw' }}></Divider>
                                <Stack width={'98%'} display={'flex'} direction={'row'}>
                                    <Button onClick={viewDocument} startIcon={<ArticleOutlinedIcon />} size='large'>{eventDetails.documentName}</Button>
                                </Stack>
                            </Fragment>
                        }

                        <Divider sx={{ width: '90vw' }}></Divider>
                        <Stack width={'98%'} display={'flex'}>
                            <Typography fontWeight={'bold'} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                Location
                            </Typography>
                            <Typography width={'100%'} ml={1} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                {locationAddress ? locationAddress : facilityAddress ? facilityAddress : ''}
                            </Typography>
                            <Typography width={'100%'} ml={1} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                {eventDetails && eventDetails.locationName ? eventDetails.locationName : eventDetails ? eventDetails.facilityName : ''}
                            </Typography>
                            <section ref={locationMapRef} id="location-map-section">
                                <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}>
                                    <Box mt={1} display={'flex'} justifyContent='center'>
                                        <Box sx={{ height: '50vh', width: '77vw', backgroundColor: 'gray' }} >
                                            <Map scrollwheel={false} mapId={'bf51a910020fa25a'} defaultCenter={{ lat: eventDetails.latitude, lng: eventDetails.longitude }} zoom={15} disableDefaultUI>
                                                <FacilityInfoMarker showInfo={false} key={`lcoation-map-${eventDetails.id}`} position={{ lat: eventDetails.latitude, lng: eventDetails.longitude }} facilityName={eventDetails.locationName ? eventDetails.locationName : eventDetails.facilityName} facilityLogoUrl={eventDetails.facilityLogoUrl} mainFacilityColor={'#14254C'} secondaryFacilityColor={'#DEDEDE'}></FacilityInfoMarker>
                                            </Map>
                                        </Box>
                                    </Box>
                                </APIProvider>
                            </section>
                        </Stack>
                        <Divider sx={{ width: '90vw' }}></Divider>
                        <Stack width={'98%'} display={'flex'}>
                            <section ref={contactRef} id="location-map-section">
                                <Typography width={'100%'} fontWeight={'bold'} mt={2} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="h6">
                                    Contact {eventDetails ? eventDetails.facilityName : ''}
                                </Typography>
                            </section>
                            <Typography width={'100%'} ml={1} mb={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                {eventDetails ? eventDetails.emailAddress : ''}
                            </Typography>
                            <Typography width={'100%'} ml={1} textAlign={'left'} color={"#14254C"} component="div" variant="body1">
                                {eventDetails ? eventDetails.phoneNumber : ''}
                            </Typography>
                        </Stack>
                        <Divider sx={{ width: '90vw' }}></Divider>
                    </Stack>
                }
                {
                    !eventDetails && !isLoading &&
                    <Box mt={5} sx={{ width: '100%' }} display={'flex'} justifyContent={'center'}>
                        <Typography variant="h6">We could not find the event are looking for</Typography>
                    </Box>
                }
            </Stack>
        </Box>
    )
}

export default FacilityEventDetail;
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Button} from '@mui/material';
import dayjs from 'dayjs';


const FacilityResceduleReservationSummary = ({isOpen, performReschedule, cancelReschedule, searchResults, reservationToView}) => {

    const rescheduleReservation = () => {
        if (performReschedule){
            performReschedule(searchResults);
        }
    }

    const handleClose = () => {
        if (cancelReschedule) {
            cancelReschedule();
        }
    }

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="reschedule-res-dialog-title"
            aria-describedby="reschedule-res-dialog-description">
            <DialogTitle sx={{ color: '#14254C' }} id="reschedule-res-dialog-title">
                Reschedule Summary
            </DialogTitle>
            <DialogContent>
                <DialogContentText mb={1} id="reschedule-res-dialog-description-from-header">
                    Reschedule From
                </DialogContentText>
                <DialogContentText id="reschedule-res-dialog-from-description">
                    {`${reservationToView.mainAreaName} - ${reservationToView.resStartDate} ${reservationToView.resStartTime} to ${reservationToView.resEndTime} `}
                </DialogContentText>
                <Box mt={1} ml={4} mr={5}>
                </Box>
                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }} ></Divider>
                <DialogContentText mb={1} id="reschedule-res-dialog-description-to-header">
                    Reschedule To
                </DialogContentText>
                <DialogContentText id="reschedule-res-dialog-to-description">
                    {`${searchResults[0].areaReserved} - ${dayjs(searchResults[0].startDate, 'YYYY-MM-DD h:mm A').format("dddd, MMM D, YYYY h:mm A")} to ${dayjs(searchResults[0].endDate, 'YYYY-MM-DD h:mm A').format("h:mm A")}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="contained" onClick={rescheduleReservation} autoFocus>
                    Reschedule
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FacilityResceduleReservationSummary;
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../../contexts/user.context";
import { retrieveCustomerReservations } from "../../../utils/facility-api/facility-service";
import { FacilityCustomerReservationsContainer } from "./facility-view-customer-reservations.styles";
import LoadingIndicator from "../../loading-indicator/loading-indicator.component";
import { Stack, Typography } from "@mui/material";
import FacilityViewCustomerReservationRow from "./facility-view-customer-reservation-row.component";


const FacilityViewCustomerReservations = ({ title, customerId, startDate, endDate, failedOnly }) => {

    const { userData } = useContext(UserContext);
    const [reservations, setReservations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        async function getReservations(customerId, facilityId, startDate, endDate) {
            setIsLoading(true);
            const response = await retrieveCustomerReservations(facilityId, customerId, startDate, endDate, failedOnly);
            if (response && response.data) {
                setReservations(response.data);
            }
            setIsLoading(false);
        }
        if (userData) {
            getReservations(customerId, userData.facilityId, startDate, endDate);
        }

    }, [userData, customerId]);

    return (
        <FacilityCustomerReservationsContainer key={`cust-reses-${startDate}-${endDate}`}>

            {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!isLoading &&
               <Stack m={1} spacing={1}>
                    {reservations && reservations.length > 0 &&
                        reservations.map(reservation => (
                            <FacilityViewCustomerReservationRow key={reservation.reservationId} reservation={reservation}></FacilityViewCustomerReservationRow>
                        ))
                    }
                    {
                        reservations.length === 0 &&
                        <Typography ml={3} fontWeight={'bold'} textAlign={'left'} gutterBottom color={"#14254C"} component="div" variant="h6">
                            None
                        </Typography>
                    }
                </Stack>
            }


        </FacilityCustomerReservationsContainer>
    )

}

export default FacilityViewCustomerReservations
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActions, Box, Stack } from '@mui/material';
import iconImg from '../../../assets/logo_picture.png'

const FacilityCard = ({ facility, filterType }) => {

    const { reset } = useContext(SearchForReservationContext);
    const { facilityName, facilitySearchTerm, facilityPhotoUrl, facilityLogoUrl, facilityId, sportsAvailable, address = {}, } = facility;
    const { city, state } = address;

    const navigate = useNavigate();

    const handleClick = () => {
        reset();
        if (filterType === 'birthday-parties') {
            navigate(`birthday-parties/${facilitySearchTerm ? facilitySearchTerm : facilityId}`);
        } else {
            navigate(`/facility-tour/${facilityId}`);
        }

    }
    sportsAvailable.map((sport) => {
        return <span key={sport} className="sport">{sport} </span>
    })

    return (
        <Card onClick={handleClick} sx={{
            width: "15vw", minHeight: "25vh", maxHeight: "55vh", display: "flex", flexDirection: 'column', alignItems: 'stretch', cursor: "pointer", ":hover": { boxShadow: "0 3px 10px #14254C" }
        }}>
            <CardMedia
                component="img"
                image={facilityPhotoUrl ? facilityPhotoUrl : facilityLogoUrl ? facilityLogoUrl : iconImg}
                placeholder
                height="150vh"
                alt={iconImg}
            />
            <CardContent sx={{ display: "flex", flexDirection: 'row', flexGrow: '1', alignItems: 'stretch' }}>
                <Stack width={'100%'} height={'100%'} spacing={1}>
                    <Typography sx={{fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }} height={'75%'} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} fontWeight={'bold'} color={'#14254C'} variant="subtitle1" component="div">
                        {facilityName}
                    </Typography>
                    <Stack width={'100%'} height={'25%'}  display={'flex'} justifyContent={'stretch'} alignContent={'flex-end'} >
                        <Typography sx={{ fontSize: { xs: '1.5vw', sm: '1.0vw', md: '1.25vw', lg: '1.0vw', xl: '1.0vw' } }}  textAlign={'right'} fontStyle={'Helvetica'} color={'#14254C'} gutterBottom variant="subtitle1" component="div" mt={0}>
                            {city}, {state}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
            {
                filterType === 'birthday-parties' &&
                <CardActions>
                    <Box sx={{ width: '95%', marginBottom: '2.0vh' }} display="flex" justifyContent="center" alignItems="center">
                        <Button variant="contained" onClick={handleClick}>
                            View Parties
                        </Button>
                    </Box>
                </CardActions>
            }

        </Card >
    )
}

export default FacilityCard;
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';


const MySportSpaceLoadingView = ({isOpen = true}) => {

    return (
        <Backdrop
        sx={{ color: '#14254C', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isOpen}
      >
        <CircularProgress sx={{color: 'white'}} />
      </Backdrop>
    )
}

export default MySportSpaceLoadingView;
import { Routes, Route } from "react-router-dom"
import FacilityEventDetail from "../../components/customer/facility-events/facility-event-detail.component";
import ViewFacilityEvents from "../../components/customer/facility-events/view-facility-events.component";

const FacilityEventRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ViewFacilityEvents/>}></Route>
            <Route path=":facilityId" element={<ViewFacilityEvents/>}></Route>
            <Route path=":facilityId/:facilityEventId" element={<FacilityEventDetail />} />
        </Routes>
    )
}

export default FacilityEventRoutes;
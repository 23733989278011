import Modal from '@mui/material/Modal';
import CustomerFormOfPayments from '../customer-form-of-payments/customer-form-of-payments.component';
import { useContext, useState } from "react";
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { Stack, Box, Button, Typography, Divider, Grid } from "@mui/material";
import { PrepareOrBookBirthdaySummary } from '../../../utils/birthday-api/birthday-service';
import { UserContext } from "../../../contexts/user.context";
import FormOfPayment from '../../form-of-payment/form-of-payment.component';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #A9A9A9',
    borderRadius: '5px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const BirthdayPayment = () => {

    const { userData } = useContext(UserContext);
    const { setAlertMessage, setIsLoading, selectedBirthdayParty, setFormOfPayment, formOfPayment, preparedSummary, setPreparedSummary, searchResponse, setConfirmation, partyInput, formFields } = useContext(BirthdayPartyContext);
    const [showBookingDialog, setShowBookingDialog] = useState(false);

    const { mainTextColor = '#14254C', secondaryTextColor = "#A9A9A9" } = selectedBirthdayParty && (!userData || (userData && !userData.isFacilityUserAccount)) ? selectedBirthdayParty : {};

    let theme = createTheme({
        palette: {
            primary: {
                main: mainTextColor
            },
            secondary: {
                main: secondaryTextColor,
            },
        },
        typography: {
            fontFamily: ['Helvetica'],
        },
        components: {
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiDivider: {
                defaultProps: {
                    color: secondaryTextColor,
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    color: "primary",
                    inputProps: {
                        style: {
                            color: mainTextColor,
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: ({ theme }) =>
                        theme.unstable_sx({
                            color: mainTextColor,
                        })
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        color: mainTextColor,
                    },
                },
            },
        },
    });

    const handleClose = (e) => {
        e.preventDefault();
        setPreparedSummary(null);
        setConfirmation(null);
    }

    const bookAndPay = async () => {

        const acceptedAncillaries = formFields.ancillaries.filter((anc) => {
            return anc.quantity && anc.quantity > 0
        });

        if (formOfPayment) {
            setPreparedSummary(null);
            setIsLoading(true);
            const customerId = partyInput.customer ? partyInput.customer.userId : userData.userId
            const response = await PrepareOrBookBirthdaySummary(selectedBirthdayParty, acceptedAncillaries, customerId, searchResponse.summaryId, true, partyInput, formFields.customFields, formOfPayment);
            setIsLoading(false);
            if (response && response.status === 200 && response.data) {
                const { status, message, failedReason, bookedBirthdayParty } = response.data;
                if (status === 'success') {
                    setConfirmation(bookedBirthdayParty);
                } else {
                    const errMessage = "Something went wrong.  We are unable to book your birthday party at this time.";
                    setAlertMessage({ message: message ? message : errMessage, failedReason: failedReason ? failedReason : null });
                }
            } else {
                setAlertMessage({ message: "Something went wrong.  We are unable to book your birthday party at this time." });
            }
        } else {
            setAlertMessage({ message: 'You must choose a form of payment.' })
        }

    }

    const formOfPaymentSelected = (formOfPaymentIn) => {
        if (formOfPaymentIn) {
            let formOfPaymentId = formOfPaymentIn.id ? formOfPaymentIn.id : formOfPaymentIn;

            // if (formOfPayment === formOfPaymentId) {
            //     setFormOfPayment(null);
            // } else {
            setFormOfPayment(formOfPaymentId);
            // }
        } else {
            setFormOfPayment(null);
        }
    }
    const closeBookingDialog = () => {
        setShowBookingDialog(false);
    }

    const openBookingDialog = () => {
        setShowBookingDialog(true);
    }

    const { ancillaryTotal = "0.00", serviceChargeTotal = "0.00", totalAmountDue = "0.00", depositAndServiceCharge = "0.00", refundableDeposit = false } = preparedSummary ? preparedSummary : {};

    return (
        <ThemeProvider theme={theme}>
            <Modal open={preparedSummary ? true : false}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={{ ...style, width: '80%' }}>
                    <Dialog
                        open={showBookingDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            BILLING NOTICE
                        </DialogTitle>
                        <DialogContent>
                            {
                                depositAndServiceCharge !== "0.00" &&
                                <DialogContentText id="alert-dialog-description">
                                    Deposit amount of ${depositAndServiceCharge} will be processed to your credit card upon booking. The remaining balance will be charged 2 days prior to the party date.
                                </DialogContentText>
                            }
                            <Divider sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            <DialogContentText id="alert-dialog-description-2">
                                You will be subject to any applicable cancellation and service fees.
                            </DialogContentText>
                            <Divider color={`${secondaryTextColor}`} sx={{ marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            <DialogContentText id="alert-dialog-description-2">
                                By confirming this booking, you are agreeing to comply with the Facility’s operational and billing policies.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeBookingDialog}>Cancel</Button>
                            <Button onClick={bookAndPay} variant="contained">Confirm</Button>
                        </DialogActions>
                    </Dialog>
                    <Box mb={5} mt={5} display="flex" justifyContent="flex-start" alignItems="center" >
                        <Typography ml={2} mt={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h5">
                            {selectedBirthdayParty ? selectedBirthdayParty.name : ''}
                        </Typography>
                    </Box>
                    <Divider sx={{ marginLeft: '1.0vw', marginTop: '2.0vh', marginBottom: '2.0vh', marginRight: '1.0vw' }}></Divider>
                    <Stack spacing={4} direction={'row'} justifyContent={'space-between'}>
                        <Box sx={{ width: '100%' }}>
                            <Typography ml={2} mt={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                                Payment Method
                            </Typography>
                            <Box ml={2} mr={2} mb={2}>
                                {
                                    userData && userData.facilityId &&
                                    <FormOfPayment facilityCustomer={partyInput.customer} formOfPayment={formOfPayment} formOfPaymentType={'Credit Card'} selectFormOfPayment={formOfPaymentSelected} allowCashCheck={false} allowInvoice={false} ></FormOfPayment>
                                }
                                {
                                    userData && !userData.facilityId &&
                                    <CustomerFormOfPayments selectFormOfPayment={formOfPaymentSelected}></CustomerFormOfPayments>
                                }

                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Typography ml={2} mt={2} mb={2} textAlign={'left'} gutterBottom fontWeight={'bold'} component="div" variant="h6">
                                Payment Summary
                            </Typography>
                            <Grid container ml={5}>
                                <Grid item xs={11} sm={5}>
                                    <Typography textAlign={'left'} gutterBottom component="div" variant="body1">
                                        Price
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                        ${selectedBirthdayParty ? selectedBirthdayParty.price : 0.00}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container ml={5}>
                                <Grid item xs={11} sm={5}>
                                    <Typography textAlign={'left'} gutterBottom component="div" variant="body1">
                                        {refundableDeposit ? 'Deposit (Refundable)' : 'Deposit'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                        ${selectedBirthdayParty ? selectedBirthdayParty.details.initialDeposit : 0.00}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container ml={5}>
                                <Grid item xs={11} sm={5}>
                                    <Typography textAlign={'left'} gutterBottom component="div" variant="body1">
                                        Optional Items
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                        ${ancillaryTotal}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container ml={5}>
                                <Grid item xs={11} sm={5}>
                                    <Typography textAlign={'left'} gutterBottom component="div" variant="body1">
                                        Service Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                        ${serviceChargeTotal}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ marginRight: '2.0vw', marginLeft: '2.0vw', marginTop: '2.0vh', marginBottom: '2.0vh' }}></Divider>
                            <Grid container ml={5}>
                                <Grid item xs={11} sm={5}>
                                    <Typography textAlign={'right'} gutterBottom fontWeight={'bold'} component="div" variant="body1">
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={10} sm={4}>
                                    <Typography textAlign={'right'} gutterBottom component="div" variant="body1">
                                        ${totalAmountDue}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                    <Box mb={5} mt={5} display="flex" justifyContent="center" alignItems="center" >
                        <Stack direction={'row'} spacing={5}>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button variant='contained' onClick={openBookingDialog}>Book and Pay</Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

export default BirthdayPayment
import { HomePageContainer } from './home-page.styles';
import { useContext, useState, useEffect } from 'react';
import SearchForReservation from '../search-for-reservation/search-for-reservation.component';
import HomePageTabBar from './home-page-tab-bar/home-page-tab-bar.component';
import HomePageFacilities from "./home-page-facilities/home-page-facilities.component";
import { SearchForReservationContext } from '../../contexts/search-for-reservation/search-for-reservation.context';
import MySportSpaceLoadingView from '../my-sport-space-loading-view/my-sport-space-loading-view.component';
import ComingSoon from '../coming-soon/coming-soon.component';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Box } from '@mui/material';
import useDeviceDetection from '../../utils/device-detection/device-detection';
import { Navigate } from 'react-router-dom';
import FacilityEventsMap from '../customer/facility-events/facility-events-map.component';
import { useSearchParams } from 'react-router-dom';

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '60vh',
    marginTop: '10vh',
    marginLeft: '2vw',
    marginRight: '2vw',
    borderRadius: '5px',
    backgroundColor: 'black'

}
const slideImages = [
    {
        url: '/baseballfield.webp'
    },
    {
        url: '/basketball-court-dark.webp'
    },
    {
        url: '/Beach_Volleyball_Courts.webp'
    },
    {
        url: '/football-field-grass.webp'
    },
    {
        url: '/soccerball.webp'
    },
    {
        url: '/OLD-BASKETBALL-COURT.webp'
    }
];

const HomePage = () => {

    const device = useDeviceDetection();
    const [searchParams] = useSearchParams();
    const selectTab = searchParams.get('selectTab');
    const { isSearchingForReservations } = useContext(SearchForReservationContext);
    const [tabSelected, setTabSelected] = useState('facilities');

    const tabBarSelected = (tab) => {
        setTabSelected(tab)
    }

    useEffect(() => {
        if (selectTab) {
            tabBarSelected(selectTab)
        }
    }, [selectTab]);

    if (device === 'Mobile') {
        return <Navigate to="/mobile-app-redirect" replace />;
    } else {

        return (
            <HomePageContainer>
                <MySportSpaceLoadingView isOpen={isSearchingForReservations}> </MySportSpaceLoadingView>
                <Box sx={{ height: '65vh' }} mt={1} mb={4}>
                    <Fade transitionDuration={3000} duration={5000} arrows={false}>
                        {slideImages.map((slideImage, index) => (
                            <div key={index}>
                                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                                </div>
                            </div>
                        ))}
                    </Fade>
                </Box>
                <Box sx={{ height: '70vh', position: 'absolute', width: '100%', zIndex: '999' }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <SearchForReservation></SearchForReservation>
                </Box>

                <Box>
                    <HomePageTabBar tabBarSelected={tabBarSelected} selectedTab={tabSelected}></HomePageTabBar>
                </Box>
                {
                    tabSelected === 'facilities' &&
                    <HomePageFacilities></HomePageFacilities>
                }
                {
                    tabSelected === 'birthdayparties' &&
                    <HomePageFacilities filterType={'birthday-parties'}></HomePageFacilities>
                }
                {
                    tabSelected === 'memberships' &&
                    <div className='mysportspace-temp-container'>
                        <ComingSoon featureText={'purchase a membership from'}></ComingSoon>
                    </div>
                }
                {
                    tabSelected === 'tournaments' &&
                    <div className='mysportspace-temp-container'>
                        <FacilityEventsMap filterType={'Tournament'}></FacilityEventsMap>
                    </div>
                }
                {
                    tabSelected === 'camps' &&
                    <div className='mysportspace-temp-container'>
                        <FacilityEventsMap filterType={'Camp'}></FacilityEventsMap>
                    </div>
                }
                {
                    tabSelected === 'leagues' &&
                    <div className='mysportspace-temp-container'>
                        <FacilityEventsMap filterType={'League'}></FacilityEventsMap>
                    </div>
                }
            </HomePageContainer >
        )
    }
}

export default HomePage;
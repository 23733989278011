import { Accordion, AccordionDetails, AccordionSummary, Typography, Box } from "@mui/material"
import FacilityAreas from "../facility-areas/facility-areas.component"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import MySportSpaceAlert from "../../alert/alert.component";

const isParentAreaInList = (facilityArea, selectedAreas) => {
    if (facilityArea.parentAreaId) {
        return selectedAreas.find((area) => area.id === facilityArea.parentAreaId);
    }
    return false;
}

const addArea = (facilityAreas, areaToAdd) => {
    //search for existing area, using the find method.
    const existingArea = facilityAreas.find((area) => area.id === areaToAdd.id);
    if (existingArea) {
        return facilityAreas;
    }

    let newAreaArr = [...facilityAreas, areaToAdd];
    if (areaToAdd.subAreas) {
        const subAreaIds = Object.keys(areaToAdd.subAreas)
        for (const id of subAreaIds) {
            newAreaArr = newAreaArr.filter((area) => {
                return area.id !== id;
            });
        }
    }

    newAreaArr.sort((area1, area2) => {
        if (area1.sortOrder < area2.sortOrder) {
            return -1;
        }

        if (area1.sortOrder > area2.sortOrder) {
            return 1;
        }

        return 0;
    });

    return newAreaArr;
}

const removeArea = (facilityAreas, facilityAreaToRemove) => {
    //search for existing cart item , using the find method.
    const existing = facilityAreas.find((area) => area.id === facilityAreaToRemove.id);
    if (existing) {
        if (!facilityAreaToRemove.subAreas) {
            return facilityAreas.filter(area => area.id !== facilityAreaToRemove.id);
        } else {
            let newAreaArray = facilityAreas.filter(area => area.id !== facilityAreaToRemove.id);
            const subAreaIds = Object.keys(facilityAreaToRemove.subAreas);
            for (const id of subAreaIds) {
                newAreaArray = newAreaArray.filter(area => area.id !== id);
            }
            return newAreaArray;
        }
    }

    return [...facilityAreas];
}


const FacilityAreaAccordion = ({ selectFacilityAreas, selectedAreas, sportFilter, selectAllFacilityAreas }) => {

    const [errorMessage, setErrorMessage] = useState('');

    const selectFacilityArea = (facilityArea, clearAll) => {

        let facilityAreasSelected = [];
        if (!clearAll) {
            const existingFacilityArea = selectedAreas.find((area) => area.id === facilityArea.id);

            if (isParentAreaInList(facilityArea, selectedAreas)) {
                setErrorMessage("You cannot deselect a sub area when the main area is selected.")
                return;
            }

            if (existingFacilityArea) {
                if (!isParentAreaInList(facilityArea, selectedAreas)) {
                    facilityAreasSelected = removeArea(selectedAreas, facilityArea);
                }
            } else {
                facilityAreasSelected = addArea(selectedAreas, facilityArea);
            }
        }

        if (selectFacilityAreas) {
            selectFacilityAreas(facilityAreasSelected);
        }
    }

    const selectAllAreas = (allFacilityAreas) => {
        selectAllFacilityAreas(allFacilityAreas);
    }

    const getFacilityAreaIds = () => {
        const facilityAreaIds = [];
        for (const sarea of selectedAreas) {
            if (sarea.subAreas) {
                const subAreaIds = Object.keys(sarea.subAreas)
                for (const id of subAreaIds) {
                    facilityAreaIds.push(id);
                }
            }

            facilityAreaIds.push(sarea.id);
        }

        return facilityAreaIds;
    }

    const closeErrorMessage = () => {
        setErrorMessage('');
    }

    return (
        <Box>
            <MySportSpaceAlert isOpen={errorMessage !== ''} message={errorMessage} okButtonText={'OK'} okAction={closeErrorMessage}></MySportSpaceAlert>
            <Accordion sx={{ marginLeft: '1.0vw', marginRight: '1.0vw' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                    aria-controls="facility-areas-content"
                    id="facility-areas-header">
                    <Typography>Search These Facility Areas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FacilityAreas sportFilter={sportFilter} header='' selectAllFacilityAreas={selectAllAreas} selectFacilityArea={selectFacilityArea} selectedFacilityAreas={getFacilityAreaIds()}></FacilityAreas>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default FacilityAreaAccordion
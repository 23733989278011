import baseApiService from "../base-api/base-api-service";

export const CreateOrUpdateBirthdayParty = async (birthdayData, facilityId, files, deletedFiles, status, imageLocationData) => {

   let formData = new FormData();
   formData.append('status', status);

   for (const file in files) {
      formData.append(`file_${file}`, files[file]);
   }

   formData.append('facilityId', facilityId);

   if (imageLocationData) {
      if (imageLocationData.imageLocationURL) {
         formData.append('imageLocationURL', imageLocationData.imageLocationURL);
      }
      
      if (imageLocationData.imageStorageLocation) {
         formData.append('imageStorageLocation', imageLocationData.imageStorageLocation);
      }
   }

   formData.append('data', JSON.stringify(birthdayData));
   if (deletedFiles.length > 0) {
      formData.append('deletedFiles', JSON.stringify(deletedFiles));
   }

   const config = {
      headers: {
         'content-type': 'multipart/form-data'
      }
   }

   try {
      let url = `birthday-parties/birthday-party`;
      return await baseApiService.post(url, formData, config);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const CopyBirthdayParties = async (facilityId, birthdayIdsToCopy) => {
   try {
      let url = `birthday-parties/birthday-party-copy`;
      const CopyBirthdayPartiesRequest = {
         facilityId: facilityId,
         birthdayIdsToCopy: birthdayIdsToCopy
      };
      return await baseApiService.post(url, CopyBirthdayPartiesRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GroupBirthdayParties = async (facilityId, birthdayIdsToGroup, groupName, existingGroupId, searchTerm, description = "", mainTextColor, secondaryTextColor, imageLocationData) => {
   try {
      let url = `birthday-parties/birthday-party-group`;
      let GroupBirthdayPartiesRequest = {
         facilityId: facilityId,
         birthdayIdsToGroup: birthdayIdsToGroup,
         description: description
      };

      if (groupName && groupName.length > 0) {
         GroupBirthdayPartiesRequest.groupName = groupName;
      }

      if (existingGroupId) {
         GroupBirthdayPartiesRequest.existingGroupId = existingGroupId;
      }

      if (searchTerm && searchTerm.length > 0){
         GroupBirthdayPartiesRequest.searchTerm = searchTerm;
      }

      if (mainTextColor){
         GroupBirthdayPartiesRequest.mainTextColor = mainTextColor;
      }
      if (secondaryTextColor){
         GroupBirthdayPartiesRequest.secondaryTextColor = secondaryTextColor;
      }

      if (imageLocationData) {
         if (imageLocationData.imageLocationURL) {
            GroupBirthdayPartiesRequest.imageLocationURL = imageLocationData.imageLocationURL;
         }
         
         if (imageLocationData.imageStorageLocation) {
            GroupBirthdayPartiesRequest.imageStorageLocation = imageLocationData.imageStorageLocation;
         }
      }

      return await baseApiService.post(url, GroupBirthdayPartiesRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GroupBirthdayGroups = async (facilityId) => {
   try {
      const url = `birthday-parties/birthday-party-groups?facilityId=${facilityId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const UpdateBirthdayPartyGroupStatus = async (facilityId, birthdayPartyIds, status) => {
   try {
      let url = `birthday-parties/birthday-party-status`;
      const UpdateStatusRequest = {
         facilityId: facilityId,
         birthdayPartyIds: birthdayPartyIds,
         updateStatus: status,
         isGroup: true
      };
      return await baseApiService.post(url, UpdateStatusRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const SearchForBirthdayParty = async (facilityId, birthdayPartyId, partyDate, searchRequest, isFacilitySearch = false) => {
   try {
      let url = `birthday-parties/search`;
      const SearchBirthdayPartiesRequest = {
         facilityId: facilityId,
         birthdayPartyId: birthdayPartyId,
         partyDate: partyDate,
         searchRequest: searchRequest,
         isFacilitySearch: isFacilitySearch
      };
      console.log(SearchBirthdayPartiesRequest);
      return await baseApiService.post(url, SearchBirthdayPartiesRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetBirthdayParties = async (facilityId, startAfterId, startAt, endAt, groupId) => {
   try {
      let url = `birthday-parties/birthday-parties?facilityId=${facilityId}`;
      if (startAfterId) {
         url = `${url}&startAfterId=${startAfterId}`;
      }
      if (startAt) {
         url = `${url}&startAt=${startAt}`;
      }
      if (endAt) {
         url = `${url}&endAt=${endAt}`;
      }
      if (groupId){
         url = `${url}&groupId=${groupId}`;
      }
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const GetBirthdayPartyGroups = async (facilityId, startAfterId, startAt, endAt, packaged) => {
   try {
      let url = `birthday-parties/groups?facilityId=${facilityId}`;
      if (startAfterId) {
         url = `${url}&startAfterId=${startAfterId}`;
      }
      if (startAt) {
         url = `${url}&startAt=${startAt}`;
      }
      if (endAt) {
         url = `${url}&endAt=${endAt}`;
      }
      if (packaged){
         url = `${url}&packaged=${packaged}`;
      }
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const GetBirthdayPartyBookings = async (facilityId, birthdayPartyId, lastId, lastDate, customerId, orderBy) => {
   try {
      let url = `birthday-parties/booked-parties?facilityId=${facilityId}`;

      if (birthdayPartyId) {
       url = `${url}&birthdayPartyId=${birthdayPartyId}`
      }

      if (lastDate) {
         url = `${url}&lastDate=${lastDate}`;
      }

      if (lastId) {
         url = `${url}&lastId=${lastId}`;
      }
      if (customerId) {
         url = `${url}&customerId=${customerId}`;
      }

      if (orderBy) {
         url = `${url}&orderBy=${orderBy}`;
      }

      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const GetBirthdayParty = async (birthdayPartyId, facilityId) => {
   try {
      let url = `birthday-parties/birthday-party?facilityId=${facilityId}&birthdayPartyId=${birthdayPartyId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const GetBookedBirthdayParty = async (bookedBirthdayPartyId) => {
   try {
      let url = `birthday-parties/booked-birthday-party?bookedBirthdayPartyId=${bookedBirthdayPartyId}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return err;
   }
}

export const GetBirthdayPartiesForFacility = async (searchTerm, isFacilitySearch = false) => {

   try {
      let url = `birthday-parties/detailed-list?searchTerm=${searchTerm}`;
      if (isFacilitySearch){
         url = `${url}&isFacilitySearch=${isFacilitySearch}`;
      }
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetBirthdayPartyDetails = async (facilitySearchTerm, partySearchTerm) => {
   try {
      let url = `birthday-parties/birthday-party-details?facilitySearchTerm=${facilitySearchTerm}&partySearchTerm=${partySearchTerm}`;
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const GetBirthdayPartiesForGroup = async (facilitySearchTerm, groupSearchTerm, isFacilitySearch = false) => {
   try {
      let url = `birthday-parties/parties-for-group?facilitySearchTerm=${facilitySearchTerm}&groupSearchTerm=${groupSearchTerm}`;
      if (isFacilitySearch){
         url = `${url}&isFacilitySearch=${isFacilitySearch}`;
      }
      return await baseApiService.get(url);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const PrepareOrBookBirthdaySummary = async (birthdayParty, acceptedAncillaryIds, customerId, summaryId, bookBirthdayParty, partyInput, customFields, paymentMethodId ) => {
   try {
      let url = `birthday-parties/book-or-summary`;
      const PreparySummaryRequest = {
         facilityId: birthdayParty.facilityId,
         birthdayPartyId: birthdayParty.id,
         acceptedAncillaries: acceptedAncillaryIds,
         customerId: customerId,
         summaryId: summaryId,
         bookBirthdayParty: bookBirthdayParty,
         partyDate: partyInput.date.format("YYYY-MM-DD"),
         startTime: partyInput.startTime.format("h:mm a"),
         endTime: partyInput.startTime.add(birthdayParty.details.length, 'hours').format("h:mm a"),
         bookedById: customerId,
         bookedByDevice: 'web',
         customFields: customFields,
      };
      if (paymentMethodId) {
         PreparySummaryRequest.paymentMethodId = paymentMethodId
      }
      return await baseApiService.post(url, PreparySummaryRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const UpdateBirthdayPartiesStatus = async (facilityId, birthdayPartyIds, status) => {
   try {
      let url = `birthday-parties/birthday-party-status`;
      const UpdateStatusRequest = {
         facilityId: facilityId,
         birthdayPartyIds: birthdayPartyIds,
         updateStatus: status
      };
      return await baseApiService.post(url, UpdateStatusRequest);
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const CancelBirthdayParty = async (bookedBirthdayPartyId, cancelType, canceledById) => {
   try {
      let url = `birthday-parties/booked-birthday-party`;
      const CancelPartyRequest = {
         bookedBirthdayPartyId: bookedBirthdayPartyId,
         canceledById: canceledById,
         cancelType: cancelType
      };
      return await baseApiService.delete(url, { data: CancelPartyRequest });
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const DeleteBirthdayParties = async (facilityId, birthdayPartyIds) => {
   try {
      let url = `birthday-parties/birthday-party`;
      const DeletePartiesRequest = {
         facilityId: facilityId,
         birthdayPartyIds: birthdayPartyIds,
      };
      return await baseApiService.delete(url, { data: DeletePartiesRequest });
   } catch (err) {
      console.log(err);
      return null;
   }
}

export const DeleteBirthdayPartyGroups = async (facilityId, groupIds) => {
   try {
      let url = `birthday-parties/groups`;
      const DeleteGroupsRequest = {
         facilityId: facilityId,
         groupIds: groupIds,
      };
      return await baseApiService.delete(url, { data: DeleteGroupsRequest });
   } catch (err) {
      console.log(err);
      return null;
   }
}
import { Fragment, useContext, useEffect, useState } from "react";
import { GetBirthdayPartyBookings } from '../../../utils/birthday-api/birthday-service';
import { UserContext } from "../../../contexts/user.context";
import { Paper, Stack, Typography, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CustomNoRowsOverlay } from "../../../utils/data-grid-utils/data-grid-utilities";
import MySportSpaceAlert from "../../alert/alert.component";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BirthdayPartyViewBooked from "./birthday-party-view-booked.component";
import { CancelBirthdayParty, GetBookedBirthdayParty } from "../../../utils/birthday-api/birthday-service";


function getRowId(row) {
    return row.id;
}

const defaultBirthdayPartyInfo = {
    birthdayParties: [],
    name: ""
}

const BirthdayPartyBookings = ({ birthdayPartyId, customerId, close, refreshAndClose }) => {

    const { userData } = useContext(UserContext);
    const [birthdaypartyInfo, setBirthdayPartyInfo] = useState(defaultBirthdayPartyInfo);
    const [isEditMode, setIsEditMode] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [selectedParties, setSelectedParties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedParty, setSelectedParty] = useState(null);
    const [cancelType, setCancelType] = useState(null);
    const [cancelThisParty, setCancelThisParty] = useState(null);

    const { birthdayParties, name } = birthdaypartyInfo;

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 100
    });

    const viewButtonClick = async (e, row) => {
        e.stopPropagation();

        setIsLoading(true);
        const response = await GetBookedBirthdayParty(row.id);
        const { status, data = {} } = response;

        if (status === 200 && data.status === 'success') {
            setSelectedParty(data.bookedBirthdayParty);
        } else {
            setSelectedParty(null);
        }
        setIsLoading(false);
    };

    const cancelSignupButtonClick = (e, row) => {
        e.stopPropagation();
        setCancelThisParty(row);
    };

    const closeAlertMessage = () => {

        if (alertMessage && alertMessage.refresh) {
            getBirthdayParties();
        }
        
        setAlertMessage(null);

    }

    const closeSelectedParty = () => {
        setSelectedParty(null);
        getBirthdayParties();
    }

    const closeView = () => {
        if (close) {
            close();
        }
    }

    const columns = [
        {
            field: 'fullName',
            renderHeader: () => (
                <strong>
                    {'Name '}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            valueGetter: (value, row) => {
                return `${row.customer.userFirstName || ''} ${row.customer.userLastName || ''}`;
            },
        },
        {
            field: 'partyDate',
            flex: 0.5,
            minWidth: 75,
            renderHeader: () => (
                <strong>
                    {'Date '}
                </strong>
            ),
        },
        {
            field: 'startTime',
            renderHeader: () => (
                <strong>
                    {'Start Time'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75
        },
        {
            field: 'endTime',
            renderHeader: () => (
                <strong>
                    {'End Time'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75
        },
        {
            field: 'price',
            renderHeader: () => (
                <strong>
                    {'Price'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            valueGetter: (value) => {
                if (!value) {
                    return ""
                }

                return `$${parseFloat(value).toFixed(2)}`
            },
        },
        {
            field: 'paidStatus',
            renderHeader: () => (
                <strong>
                    {'Status'}
                </strong>
            ),
            flex: 0.5,
            minWidth: 75,
            valueGetter: (value, row) => {
                return row.cancelData ? 'Canceled' : row.reservationCharge ? row.reservationCharge.paidStatus.toUpperCase() : "UNPAID"
            },
        },
        {
            field: 'viewButton',
            headerName: '',
            description: '',
            sortable: false,
            flex: 0.5,
            minWidth: 75,
            renderCell: (params) => {
                return (
                    <Stack spacing={1} mt={1} direction={'row'}>
                        <Button
                            onClick={(e) => viewButtonClick(e, params.row)}
                            variant="contained"
                        >
                            View
                        </Button>
                        {
                            !params.row.cancelData &&
                            <Button
                                onClick={(e) => cancelSignupButtonClick(e, params.row)}
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        }

                    </Stack>
                );
            },
        }
    ];

    async function getBirthdayParties(nextPage) {
        setIsLoading(true);
        let partyInfo = defaultBirthdayPartyInfo;
        if (userData && userData.facilityId) {
            const response = await GetBirthdayPartyBookings(userData.facilityId, birthdayPartyId, null, null, customerId);
            if (response && response.status === 200) {
                if (!response.data.status || response.data.status === 'success') {
                    partyInfo = response.data;
                }
            }

            setBirthdayPartyInfo(partyInfo);
            setIsLoading(false);
        }

    }

    useEffect(() => {

        getBirthdayParties(true);

    }, [userData, paginationModel.page]);

    const closeCancelDialog = () => {
        setCancelThisParty(null);
    }

    const cancelBirthdayParty = () => {

    }

    const selectParty = (id) => {

    }

    const applyPoliciesAndCancel = () => {
        cancelTheParty("apply_policies");
    }

    const refundDepositAndCancel = () => {
        cancelTheParty("refund_deposit");
    }

    const fullRefund = () => {
        cancelTheParty("full_refund");
    }

    const cancelTheParty = async (cancelType) => {

        setIsLoading(true);
        const response = await CancelBirthdayParty(cancelThisParty.id, cancelType, userData.userId);
        if (response && response.status === 200) {
            const { message } = response.data;
            if (!response.data.status || response.data.status === 'success') {
                setAlertMessage({
                    title: 'Canceled',
                    message: message ? message : 'The birthday party has been canceled.',
                    close: true,
                    refresh: true
                }
                );
                closeCancelDialog();
            } else {
                if (message) {
                    setAlertMessage({
                        title: 'Cancel Error',
                        message: message
                    });
                } else {
                    setAlertMessage({
                        title: 'Cancel Error',
                        message: "There was an issue canceling this birthday party.  The birthday party could not be canceled."
                    });
                }
            }
        } else {
            setAlertMessage({
                title: 'Cancel Error',
                message: "There was an issue canceling this birthday party.  The birthday party could not be canceled."
            });
        }
        setIsLoading(false);
    }

    return (
        <Fragment>
            <Paper>
                <Stack sx={{
                    height: '85vh',
                    width: '98%',
                    marginLeft: '1.0vw',
                    marginRight: '1.0vw'
                }}>
                    <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button onClick={closeView} startIcon={<CloseOutlinedIcon />}>
                        </Button>
                    </Box>
                    <Box mt={1} mb={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography textAlign={'center'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h5" component="div" ml={1} mt={1} mb={1}>
                            {name ? name : 'Booked Birthday Parties'}
                        </Typography>
                    </Box>
                    <DataGrid sx={{
                        font: 'Helvetica', color: '#14254C',
                        fontWeight: 400, '--DataGrid-overlayHeight': '500px'
                    }} getRowId={getRowId} loading={isLoading} rowCount={1} initialState={{ pagination: paginationModel }} pageSizeOptions={[100]} onPaginationModelChange={setPaginationModel} paginationMode="server"
                        rows={birthdayParties} columns={columns} checkboxSelection={isEditMode} disableRowSelectionOnClick={true} rowSelectionModel={selectedParties} onRowSelectionModelChange={(ids) => {
                            if (!isEditMode) {
                                selectParty(ids);
                            } else {
                                setSelectedParties(ids)
                            }
                        }}
                        slots={{ noRowsOverlay: CustomNoRowsOverlay }} />
                </Stack>
            </Paper>
            <BirthdayPartyViewBooked bookedParty={selectedParty} close={closeSelectedParty}></BirthdayPartyViewBooked>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ''} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            <Dialog
                open={!isLoading && cancelThisParty ? true : false}
                onClose={closeCancelDialog}
            >
                <DialogTitle>Party Cancelation</DialogTitle>
                <DialogContent>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Please choose the type of cancelation.
                    </DialogContentText>
                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                        Apply Policies
                    </Typography>
                    <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={1}>
                        This will apply your facilities cancelation policy to refund or charge for the cancelation.  Any refundable deposit will also be refunded.
                    </Typography>
                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                        Refund Deposit
                    </Typography>
                    <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={1}>
                        This will refund the deposit only.  This overrides the refundable flag set on the party.
                    </Typography>
                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={3} mt={1}>
                        Full Refund
                    </Typography>
                    <Typography textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={4} mt={1} mb={3}>
                        This will refund the deposit and any paid payments.
                    </Typography>
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        All cancelations are final and cannot be undone.  Any MSS application fee(s) will not be refunded.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={closeCancelDialog}>Don't Cancel</Button>
                    <Button size="small" onClick={applyPoliciesAndCancel} variant="contained">Apply Policies</Button>
                    <Button size="small" onClick={refundDepositAndCancel} variant="contained">Refund Deposit</Button>
                    <Button size="small" onClick={fullRefund} variant="contained">Full Refund</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={cancelType ? true : false}
                onClose={closeCancelDialog}
            >
                <DialogTitle>Party Cancelation</DialogTitle>
                <DialogContent>
                    {
                        cancelType && cancelType === 'apply_policies' &&
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            Your cancel policies will be applied and the birthday party will be canceled.  If the deposit is refundable the customer will receive a refund.
                        </DialogContentText>
                    }
                    {
                        cancelType && cancelType === 'refund_deposit' &&
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            The deposit will be refunded and the birthday party will be canceled.
                        </DialogContentText>
                    }
                    {
                        cancelType && cancelType === 'facility_void' &&
                        <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                            The deposit and birthday party will be refunded.  The facility will be charged the My Sport Space application fee for both the deposit and the total amount.
                        </DialogContentText>
                    }
                    <DialogContentText gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="body1" component="div" ml={2}>
                        Are you sure you want to cancel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCancelDialog}>No</Button>
                    <Button onClick={cancelTheParty} variant="contained">Yes</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )

}

export default BirthdayPartyBookings
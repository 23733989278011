import { Fragment, useContext, useState, useEffect } from "react";
import { ReservationSearchContainer } from "./reservation-search-form.styles.jsx"
import Box from '@mui/material/Box';
import { Container, Stack, Switch, IconButton } from "@mui/material";
import SportsAndSubType from "../../../sports-and-subtype/sports-and-subtype.component.jsx";
import SearchDateInput from "../../../search-for-reservation/search-date-input/search-date-input.component.jsx";
import Typography from '@mui/material/Typography';
import { TextField } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MaterialUITimePicker from "../../../material-ui/time-picker/material-ui-time-picker.component.jsx";
import dayjs from "dayjs";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { facilityReservationSearch } from "../../../../utils/facility-api/facility-service.js";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../../../contexts/user.context.jsx";
import MySportSpaceAlert from "../../../alert/alert.component.jsx";
import { ApplicationDataContext } from "../../../../contexts/application-data-context.jsx";
import { FacilityAreaContext } from "../../../../contexts/facility/facility-area-provider.context.jsx";
import { SearchForReservationContext } from "../../../../contexts/search-for-reservation/search-for-reservation.context.jsx";
import FacilityCustomerSearch from "../../facility-customer-search/facility-customer-search.component.jsx";
import { FacilityReservationsContext } from "../../../../contexts/facility/facility-reservations.context.jsx";
import { getReservationDatesList } from "../../../../utils/date-utilities/date-utilities.js";
import { FacilityHoursOfOperationContext } from "../../../../contexts/facility/facility-hours-of-operation.context.jsx";
import MySportSpaceLoadingView from "../../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FacilityAreaAccordion from "../../facility-area-accordion/facility-area-accordion.component.jsx";

var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const ReservationSearchForm = () => {

    const { reservationSearchData, setReservationSearchData, reset, isSearchingForReservations, setIsSearchingForReservations, setSearchResults, setReservationWarnings } = useContext(SearchForReservationContext);
    const { startTime, endTime, reservationLength, customer } = reservationSearchData;
    const [searchByStartTime, setSearchByStartTime] = useState(true);
    const [searchByAreaType, setSearchByAreaType] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);
    const [validationMessage, setValidationMessage] = useState('');
    const { userData } = useContext(UserContext);
    const { sportToSubAreas } = useContext(ApplicationDataContext);
    const { sportsForAreas } = useContext(FacilityAreaContext);
    const { reservationsDate } = useContext(FacilityReservationsContext);
    const { hoursOfOperation, hoursOfOperationExceptions } = useContext(FacilityHoursOfOperationContext);
    const [showHoursOfOperationWarning, setShowHoursOfOperationWarning] = useState(false);
    const [isSearchingForCustomer, setIsSearchingForCustomer] = useState(false);

    const navigate = useNavigate();

    const tenPm = dayjs().startOf('day').add(22, 'hour');

    useEffect(() => {

        const setEarlyReservationTimes = (searchDate) => {
            let request = Object.assign({}, reservationSearchData);

            request.startTime = dayjs(`${dayjs(searchDate).format("YYYY-MM-DD")} 6:00 AM`, 'YYYY-MM-DD h:mm A');
            request.endTime = dayjs(`${dayjs(searchDate).format("YYYY-MM-DD")} 10:00 PM`, 'YYYY-MM-DD h:mm A');
            setReservationSearchData(request);
        }

        const setTodayReservationTimes = (searchDate) => {
            let request = Object.assign({}, reservationSearchData);

            request.startTime = dayjs().startOf('hour');
            request.endTime = dayjs().startOf('hour').add(1, 'hour').isAfter(tenPm) ? dayjs().startOf('hour').add(1, 'hour') : tenPm;
            setReservationSearchData(request);
        }

        const { searchDate } = reservationSearchData;
        if (dayjs(searchDate).day() !== dayjs().day()) {
            setEarlyReservationTimes(searchDate);
        } else {
            setTodayReservationTimes(searchDate);
        }

    }, [reservationSearchData.searchDate]);

    const returnToCalendar = () => {
        reset();
        navigate("/facility/calendar");
    }

    const resetReservationForm = () => {
        showAlert('Reset Search Form?', 'Are you sure you want to reset the reservation search?');
    }

    const performReservationFormReset = () => {
        reset();
        setAlertMessage('');
        setSearchByStartTime(false);
    }

    // const selectFacilityCustomer = (facilityCustomer) => {

    //     let request = Object.assign({}, reservationSearchData);
    //     request.customer = facilityCustomer;

    //     if (facilityCustomer.userFirstName === "Internal" && facilityCustomer.userLastName === "Booking") {
    //         request.isInternalBooking = true;
    //     } else {
    //         request.isInternalBooking = false;
    //     }

    //     setReservationSearchData(request);
    // }

    const selectStartTime = (time) => {

        let request = Object.assign({}, reservationSearchData);
        request.startTime = time;

        let startMinute = time.minute();
        if (startMinute !== 0 && startMinute !== 15 && startMinute !== 30 && startMinute !== 45) {
            if (startMinute > 0 && startMinute < 15) {
                request.startTime = time.startOf('hour');
            } else if (startMinute > 15 && startMinute < 30) {
                request.startTime = time.startOf('hour').add(15, 'minute');
            } else if (startMinute > 30 && startMinute < 45) {
                request.startTime = time.startOf('hour').add(30, 'minute');
            } else {
                request.startTime = time.startOf('hour').add(45, 'minute');
            }
        }

        if (!searchByStartTime) {
            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;
            }

            request.reservationLength = diffInHours;
        }
        setReservationSearchData(request);
    }

    const selectEndTime = (time) => {

        let request = Object.assign({}, reservationSearchData);
        request.endTime = time;

        let endMinute = time.minute();
        if (endMinute !== 0 && endMinute !== 15 && endMinute !== 30 && endMinute !== 45) {
            if (endMinute > 0 && endMinute < 15) {
                request.endTime = time.startOf('hour');
            } else if (endMinute > 15 && endMinute < 30) {
                request.endTime = time.startOf('hour').add(15, 'minute');
            } else if (endMinute > 30 && endMinute < 45) {
                request.endTime = time.startOf('hour').add(30, 'minute');
            } else {
                request.endTime = time.startOf('hour').add(45, 'minute');
            }
        }

        if (!searchByStartTime) {
            let diffInHours = request.startTime.diff(request.endTime, 'hour', true);
            if (diffInHours < 0) {
                diffInHours = diffInHours * -1;
            }

            request.reservationLength = diffInHours;
        }

        setReservationSearchData(request);
    }

    const toggleSearchByAreaType = () => {
        setSearchByAreaType(!searchByAreaType);

        setSelectedAreas([]);

    }

    const toggleSearchByStartTime = () => {
        setSearchByStartTime(!searchByStartTime);

        let request = Object.assign({}, reservationSearchData);
        request.reservationLength = 1.0;
        request.startTime = dayjs().startOf('hour');
        request.endTime = dayjs().startOf('hour').add(1, 'hour');
        setReservationSearchData(request);

    }

    const setSelectedAreas = (selectedAreas) => {
        let request = Object.assign({}, reservationSearchData);
        request.selectedAreas = selectedAreas;
        setReservationSearchData(request);
    }

    const handleCustomerChange = (customerName, customer) => {

        if (!customer) {
            setSearchResults(null);
        }

        let request = Object.assign({}, reservationSearchData);
        request.customer = customer;
        request.searchCustomerName = customerName;

        setReservationSearchData(request);
    }

    const selectResLength = (event) => {
        var showMessage = false;
        let request = Object.assign({}, reservationSearchData)

        let length = event.target.value
        if (parseFloat(length) > 24.0) {
            request.reservationLength = 24.0;
        } else {
            let result = `${length}`.indexOf(".");
            if (result >= 0) {
                let decimalValue = length.substring(result, length.end);
                if (decimalValue.length > 3) {
                    return;
                }

                if (decimalValue === '.2' || decimalValue === '.7') {
                    let existingResult = `${reservationLength}`.indexOf(".");
                    let existingDecimalValue = `${reservationLength}`.substring(existingResult, reservationLength.end);
                    if (existingDecimalValue === '.25' || existingDecimalValue === '.75') {
                        request.reservationLength = parseInt(length.substring(0, existingResult));
                    } else {
                        request.reservationLength = parseFloat(`${length}5`);
                    }

                } else if (decimalValue !== '.25' && decimalValue !== '.5' && decimalValue !== '.75') {
                    showMessage = true;
                    request.reservationLength = parseInt(length.substring(0, result));
                } else {
                    request.reservationLength = length;
                }
            } else {
                request.reservationLength = length;
            }
        }
        setReservationSearchData(request);

        if (showMessage) {
            showValidationMessage('Valid values are increments of .25 (ex 1, 1.25, 1.5, 1.75) or use the arrows to increment.')
        }
    }

    const closeValidationMessage = (e) => {
        setValidationMessage('')
    }

    const showValidationMessage = (message) => {
        setValidationMessage(message);
    }

    const showAlert = (title, message) => {
        setAlertMessage({
            title: title,
            message: message
        });
    }

    const closeAlertMessage = () => {
        setAlertMessage('');
    }

    const closeHoursOfOperationMessage = () => {
        setShowHoursOfOperationWarning(false);
    }

    const performReservationSearch = async () => {
        searchForReservations(false);
    }
    const overrideFacilityHoursAndSearch = async () => {
        searchForReservations(true);
    }

    const searchForReservations = async (ignoreHoursOfOperationCheck = false) => {

        setShowHoursOfOperationWarning(false);

        let performSearch = true;

        if (!reservationSearchData.selectedSubType && !reservationSearchData.facilityArea && reservationSearchData.selectedAreas.length <= 0) {
            showValidationMessage("You must select an area type or a facility area.");
            performSearch = false;
        }

        if (!reservationSearchData.selectedSport) {
            showValidationMessage("You must select a sport.");
            performSearch = false;
        }

        if (!reservationSearchData.customer) {
            showValidationMessage('You must select a customer.');
            performSearch = false;
        }

        if (reservationSearchData.repeatsInterval && reservationSearchData.repeatsInterval !== "None" && !reservationSearchData.repeatsUntilDate) {
            showValidationMessage('A repeating reservation must have a repeat until date.');
            performSearch = false;
        }

        if ((reservationSearchData.repeatsInterval === 'Weekly' || reservationSearchData.repeatsInterval === 'Monthly') && (!reservationSearchData.daysOfWeek || reservationSearchData.daysOfWeek.length <= 0)) {
            showValidationMessage('You must select a repeating day of week.');
            performSearch = false;
        }

        if (!startTime) {
            showValidationMessage('You must select a search start time.');
            performSearch = false;
        }

        if (!endTime) {
            showValidationMessage('You must select a search end time.');
            performSearch = false;
        }

        if (startTime.isAfter(endTime)) {
            showValidationMessage("Start Time must be less than or equal to End Time.");
            performSearch = false;
        }

        if (endTime.isBefore(startTime)) {
            showValidationMessage("End Time must be greater than or equal to Start Time.");
            performSearch = false;
        }

        if (!ignoreHoursOfOperationCheck) {
            if (performSearch) {
                let reservationDates = getReservationDatesList(reservationSearchData.searchDate, reservationSearchData.repeatsUntilDate, reservationSearchData.daysOfWeek, reservationSearchData.exceptionDates, false, 'YYYY-MM-DD');
                for (const resDate of reservationDates) {

                    let hop = null;
                    let amPmFormat = 'A';
                    if (hoursOfOperationExceptions) {
                        const resDateKey = dayjs(resDate, 'YYYY-MM-DD').format('dddd, MMM D, YYYY');
                        hop = hoursOfOperationExceptions[resDateKey];
                        if (hop) {
                            amPmFormat = 'a';
                        }
                    }
                    if (!hop) {
                        const dow = dayjs(resDate, 'YYYY-MM-DD').format('d');
                        hop = hoursOfOperation[dow];
                    }
                    if (hop) {
                        const hopStart = dayjs(`${resDate} ${hop.startTime}`, `YYYY-MM-DD h:mm ${amPmFormat}`);
                        const hopEnd = dayjs(`${resDate} ${hop.endTime}`, `YYYY-MM-DD h:mm ${amPmFormat}`);
                        const resStart = dayjs(`${resDate} ${reservationSearchData.startTime.format("h:mm a")}`, `YYYY-MM-DD h:mm a`);
                        const resEnd = dayjs(`${resDate} ${reservationSearchData.endTime.format("h:mm a")}`, `YYYY-MM-DD h:mm a`);
                        if (resStart.isBefore(hopStart) || resStart.isAfter(hopEnd) || resEnd.isBefore(hopStart) || resEnd.isAfter(hop)) {
                            //show the warning message
                            setShowHoursOfOperationWarning(true);
                            performSearch = false;
                            return;
                        }
                    }
                }
            }
        }

        if (performSearch) {

            setIsSearchingForReservations(true);
            const response = await facilityReservationSearch(reservationSearchData, sportToSubAreas, userData.facilityId, userData.internalBookingId, true, null, null, searchByStartTime ? false : true);
            const { searchResults, errorMessage, reservationWarnings } = response.data;
            console.log(searchResults);
            if (response.status === 200) {
                if (errorMessage) {
                    setSearchResults([]);
                    setReservationWarnings([]);
                    showValidationMessage(errorMessage);
                } else {
                    let sortedResults = searchResults.filter((result) => {
                        return result.reservationId !== "PLACEHOLDER"
                    }).sort((res1, res2) => {

                        const res1Date = dayjs(res1.startDate, 'YYYY-MM-DD h:mm a');
                        const res2Date = dayjs(res2.startDate, 'YYYY-MM-DD h:mm a');

                        if (res1Date.isBefore(res2Date)) {
                            return 1;
                        }

                        if (res1Date.isAfter(res2Date)) {
                            return -1;
                        }

                        return 0;
                    });
                    setSearchResults(sortedResults);
                    setReservationWarnings(reservationWarnings);
                }
            } else {
                setSearchResults([]);
                setReservationWarnings([]);
            }

            setIsSearchingForReservations(false);
        }

        return;
    }

    const setAsInternalBooking = () => {

        let request = Object.assign({}, reservationSearchData);

        if (!userData.internalBookingId) {
            showAlert("Internal Bookings Unavailable", "Please log out of your account and then log back in.\n\n If the problem persists, please contact My Sport Space.")
        } else {

            if (customer && customer.userId === userData.internalBookingId) {
                request.customer = null;
                request.isInternalBooking = false;
                request.searchCustomerName = "";
                setSearchResults([]);
            } else {
                request.customer = {
                    userId: userData.internalBookingId,
                    userFirstName: "Internal",
                    userLastName: "Booking"
                };
                request.searchCustomerName = "Internal Booking";
                request.isInternalBooking = true;
                setSearchResults([]);
            }

            setReservationSearchData(request);
        }
    }

    const toggleIsSearchingForCustomer = (searching) => {
        setIsSearchingForCustomer(searching)
    }

    return (
        <ReservationSearchContainer>
            <MySportSpaceLoadingView isOpen={isSearchingForReservations || isSearchingForCustomer}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} title={alertMessage ? alertMessage.title : ''} message={alertMessage ? alertMessage.message : ""} okButtonText={'Yes'} cancelButtonText={'No'} okAction={performReservationFormReset} cancelAction={closeAlertMessage}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={showHoursOfOperationWarning} title={'Override?'} message={'Your search times are outside of the hours of operation, would you like to override and continue booking a reservation?'} okButtonText={'Yes'} cancelButtonText={'No'} okAction={overrideFacilityHoursAndSearch} cancelAction={closeHoursOfOperationMessage}></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={validationMessage !== ''} message={validationMessage} okButtonText={'OK'} okAction={closeValidationMessage}></MySportSpaceAlert>
            <Box>
                <Stack mt={1} mb={1} spacing={2} direction={'row'} display={'flex'} alignItems={'center'} alignContent={'center'}>
                    <IconButton onClick={returnToCalendar}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography sx={{ ml: '1.0vw', mt: '2.0vh', mb: '2.0vh' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div">
                        {'New Reservation Search'}
                    </Typography>
                </Stack>

                <Stack ml={1} mr={2} direction={'column'} spacing={3}>
                    <Container sx={{ boxShadow: "0 3px 10px #DEDEDE", borderRadius: '5px' }}>
                        <Divider sx={{ color: "#14254C", marginTop: '1.0vh' }} textAlign="left">Select A Customer </Divider>
                        <Box mt={2} mb={2}>
                            <FacilityCustomerSearch setIsSearchingForCustomers={toggleIsSearchingForCustomer} searchCustomerName={reservationSearchData.searchCustomerName} customer={reservationSearchData.customer} handleCustomerChange={handleCustomerChange}></FacilityCustomerSearch>
                        </Box>
                        <Divider sx={{ color: "#14254C" }} textAlign="left"> Or </Divider>
                        <FormControlLabel disableTypography sx={{ marginLeft: '2.0vh', marginBottom: '1.0vh', color: '#14254C', fontSize: '1.75vh' }} control={<Switch name="Is" checked={customer && customer.userId === userData.internalBookingId} onChange={setAsInternalBooking} />} label="Internal Booking" />
                    </Container>
                    {customer &&
                        <Fragment>
                            <Container sx={{ boxShadow: "0 3px 10px #DEDEDE", borderRadius: '5px' }}>
                                <Stack>
                                    <FormControlLabel sx={{ color: "#14254C" }} control={<Checkbox checked={searchByAreaType} onChange={toggleSearchByAreaType} />} label="Search By Area Type" />
                                    <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                        {'Search for any area matching the specified search criteria'}
                                    </Typography>
                                    {
                                        searchByAreaType &&
                                        <Box mt={2}>
                                            <SportsAndSubType spacesText={"Spaces"} position="relative" showSports={true} sportsOverride={sportsForAreas} flexDirection={'row'} showShadow={false} showButtons={false} width={'100%'} hideAreaAndSpaces={false} />
                                        </Box>
                                    }

                                    <FormControlLabel sx={{ color: "#14254C" }} control={<Checkbox checked={!searchByAreaType} onChange={toggleSearchByAreaType} />} label="Search By Areas" />
                                    <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                        {'Search in the specific area(s) that you select'}
                                    </Typography>
                                    {
                                        !searchByAreaType &&
                                        <Box mt={2} mb={2}>
                                            <SportsAndSubType spacesText={"Spaces"} position="relative" showSports={true} sportsOverride={sportsForAreas} flexDirection={'row'} showShadow={false} showButtons={false} width={'100%'} hideAreaAndSpaces={true} />
                                            <FacilityAreaAccordion sportFilter={reservationSearchData.selectedSport} selectAllFacilityAreas={setSelectedAreas} selectFacilityAreas={setSelectedAreas} selectedAreas={reservationSearchData.selectedAreas} ></FacilityAreaAccordion>
                                        </Box>
                                    }
                                </Stack>
                            </Container>
                            <Container sx={{ boxShadow: "0 3px 10px #DEDEDE", borderRadius: '5px' }}>
                                <Box mt={2} mb={2}>
                                    <SearchDateInput initialDate={dayjs(reservationsDate)} showButtons={false} showShadow={false} showDatesInput={true} position="relative"></SearchDateInput>
                                </Box>
                            </Container>
                            <Container sx={{ boxShadow: "0 3px 10px #DEDEDE", borderRadius: '5px' }}>
                                <Stack mt={1} mb={1} spacing={2}>
                                    <Stack>
                                        <FormControlLabel sx={{ color: "#14254C" }} control={<Checkbox checked={searchByStartTime} onChange={toggleSearchByStartTime} />} label="Search By Start Time Range" />
                                        <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                            {'Search for reservations that start in the given start range'}
                                        </Typography>
                                    </Stack>

                                    {
                                        searchByStartTime &&
                                        <Stack spacing={1} direction={'row'}>
                                            <MaterialUITimePicker headerText="Search Earliest End Time" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} />
                                            <MaterialUITimePicker headerText="Search Latest End Time" views={['hours', 'minutes']} format={'h:mm A'} value={endTime} minTime={startTime} onChange={selectEndTime} />
                                            <TextField sx={{ minWidth: '25%' }} id="duration" variant="outlined" label={'Duration'} type="number" onChange={selectResLength} name="duration" value={reservationLength}
                                                inputProps={{
                                                    step: "0.25",
                                                    max: "24",
                                                    min: ".5",
                                                    pattern: "^\d*\.\d{2}$"
                                                }} ></TextField>
                                        </Stack>
                                    }
                                    <Stack>
                                        <FormControlLabel sx={{ color: '#14254C' }} control={<Checkbox checked={!searchByStartTime} onChange={toggleSearchByStartTime} />} label="Choose Start and End Time" />
                                        <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                            {'Search for reservations using a specific start and end time'}
                                        </Typography>
                                    </Stack>
                                    {
                                        !searchByStartTime &&
                                        <Stack direction="row"
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                            spacing={0}
                                        >
                                            <MaterialUITimePicker headerText="Reservation Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} />
                                            <MaterialUITimePicker headerText="Reservation End Time" views={['hours', 'minutes']} format={'h:mm A'} value={endTime} onChange={selectEndTime} minTime={startTime} />
                                        </Stack>
                                    }
                                </Stack>
                            </Container>
                            <Divider variant="inset" />
                        </Fragment>
                    }
                </Stack>

                <Stack mt={5} mb={5} direction={'row'} spacing={2} justifyContent={'center'} >
                    {customer &&
                        <Button variant="outlined" size="large" onClick={resetReservationForm} >Reset</Button>
                    }

                    <Button variant="contained" size="large" onClick={returnToCalendar} >Return To Calendar</Button>
                    {customer &&
                        <Button variant="contained" size="large" onClick={performReservationSearch} >Search</Button>
                    }
                </Stack>
            </Box >

        </ReservationSearchContainer >

    )

}

export default ReservationSearchForm;
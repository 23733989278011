import FacilityAreaCheckBox from '../../../facility/facility-area-check-box/facility-area-check-box.component';
import { useState, useContext, useEffect } from "react";
import { CalendarSettingsContext } from '../../../../contexts/customer/calendar-settings.context';
import { UserContext } from "../../../../contexts/user.context";
import { SearchForReservationContext, reservationSearchDataDefault } from '../../../../contexts/search-for-reservation/search-for-reservation.context';
import { facilityReservationSearch } from "../../../../utils/facility-api/facility-service";
import { useNavigate } from "react-router-dom";
import MySportSpaceLoadingView from "../../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import { findSportsForArea } from "../../../../utils/search-utils/search-utils";
import dayjs from "dayjs";
import { Box, Button, Stack, Typography, Popover } from "@mui/material";
import MaterialUITimePicker from "../../../material-ui/time-picker/material-ui-time-picker.component";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import MySportSpaceAlert from '../../../alert/alert.component';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    disablePortal: true,
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            whiteSpace: 'normal'
        },
    },
};

const ClickToBook = ({ searchTerm }) => {

    const { facilityId, calendarSettings, reservationsDate } = useContext(CalendarSettingsContext);
    const { setSearchResults, isSearchingForReservations, setIsSearchingForReservations, setBackToSearchUrl, clickToBookOpenSlot, setClickToBookOpenSlot } = useContext(SearchForReservationContext);
    const { mainFacilityColor } = calendarSettings;

    const bookingTimesDefault = {
        startTime: clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.startDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour'),
        endTime: clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour').add(1, 'hour'),
    }

    const [bookingTimes, setBookingTimes] = useState(bookingTimesDefault);
    const [selectedArea, setSelectedArea] = useState(clickToBookOpenSlot ? clickToBookOpenSlot.facilityArea : null);
    const { currentUser, userData } = useContext(UserContext);
    const [selectedSport, setSelectedSport] = useState(null);
    const [sports, setSports] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [signInAlertMessage, setSignInAlertMessage] = useState(null);

    const navigate = useNavigate();


    useEffect(() => {

        const setupClickToBookOpenSlot = async (openSlot, bTimesDefault) => {

            if (openSlot && openSlot.facilityArea) {
                const areaSports = findSportsForArea(openSlot.facilityArea);
                setSelectedSport(areaSports.length > 0 ? areaSports[0] : null);
                setSports(areaSports);
                setSelectedArea(openSlot.facilityArea);
            }

            if (openSlot && openSlot.maxEndDate) {

                const totalLengthOfSlotinMs = openSlot.maxEndDate - openSlot.startDate;
                const totalLengthInSeconds = totalLengthOfSlotinMs / 1000;
                if (totalLengthInSeconds >= 3600) {
                    bTimesDefault.endTime = bTimesDefault.startTime.add(1, 'hour');
                    setBookingTimes(bTimesDefault);
                } else {
                    setBookingTimes(bTimesDefault);
                }
            }
        }

         setupClickToBookOpenSlot(clickToBookOpenSlot, bookingTimesDefault);
    }, []);

    // useEffect(() => {
    //     if (clickToBookOpenSlot) {
    //         if (bookingTimes.endTime.isBefore(bookingTimes.startTime)) {

    //             setAlertMessage("The end time cannot be before start time.")

    //             let times = Object.assign({}, bookingTimes);
    //             times.endTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.endDate.format("YYYY-MM-DD h:mm a")) : dayjs().startOf('hour').add(1, 'hour')
    //             setBookingTimes(times);
    //         } else if (bookingTimes.endTime.isAfter(clickToBookOpenSlot.maxEndDate)) {
    //             setAlertMessage("The end time must be within the allowed calendar space.");
    //             let times = Object.assign({}, bookingTimes);
    //             times.endTime = dayjs(clickToBookOpenSlot.maxEndDate.format("YYYY-MM-DD h:mm a"));
    //             setBookingTimes(times);
    //         } else if (bookingTimes.endTime.isSame(bookingTimes.startTime)) {
    //             setAlertMessage("The end time must be greater than start time");
    //             let times = Object.assign({}, bookingTimes);
    //             times.endTime = dayjs(clickToBookOpenSlot.endDate.format("YYYY-MM-DD h:mm a"));
    //             setBookingTimes(times);
    //         }
    //     }


    // }, [bookingTimes]);


    const selectFacilityArea = (facilityArea) => {

        const areaSports = findSportsForArea(facilityArea);
        setSelectedSport(areaSports.length > 0 ? areaSports[0] : null);
        setSports(areaSports);

        setSelectedArea(facilityArea);
    }

    const selectStartTime = (time) => {

        let newStartTime = null;
        let newEndTime = null;
        if (time) {
            const minutes = time.minute();
            let remainder = 0;
            if (minutes != 0 && minutes != 30) {
                remainder = 15 - (minutes % 15);
            }
            newStartTime = time.add(remainder, "minutes");
        } else {
            newStartTime = bookingTimesDefault.startTime;
            newEndTime = bookingTimesDefault.endTime;
        }
        setBookingTimes({
            startTime: newStartTime ? newStartTime : null,
            endTime: newEndTime ? newEndTime : bookingTimes.endTime
        })
    }

    const selectEndTime = (time) => {

        let newEndTime = null;
        let newStartTime = null;
        if (time) {
            const minutes = time.minute();
            console.log(minutes)
            let remainder = 0;
            if (minutes != 0 && minutes != 30) {
                remainder = 15 - (minutes % 15);
            }
            newEndTime = time.add(remainder, "minutes");
        } else {
            newStartTime = bookingTimesDefault.startTime;
            newEndTime = bookingTimesDefault.endTime;
        }

        setBookingTimes({
            startTime: newStartTime ? newStartTime : bookingTimes.startTime,
            endTime: newEndTime
        })
    }

    const close = () => {
        setClickToBookOpenSlot(null);
    }

    const selectSport = (e) => {
        setSelectedSport(e.target.value)
    }

    const bookReservation = async () => {

        if (!currentUser || !userData) {
            //alert('You must be logged in to book a reservation.')
            setSignInAlertMessage('You must be signed in to book a reservation')
            return;
        }

        if (bookingTimes.endTime.isBefore(bookingTimes.startTime)) {

            setAlertMessage("The end time cannot be before start time.")

            let times = Object.assign({}, bookingTimes);
            times.endTime = clickToBookOpenSlot ? dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a")) : dayjs().startOf('hour').add(1, 'hour')
            setBookingTimes(times);

            return;
        } else if (bookingTimes.endTime.isAfter(clickToBookOpenSlot.maxEndDate)) {
            setAlertMessage("The end time must be within the allowed calendar space.");
            let times = Object.assign({}, bookingTimes);
            times.endTime = dayjs(clickToBookOpenSlot.maxEndDate.format("YYYY/MM/DD h:mm a"));
            setBookingTimes(times);
            return;
        } else if (bookingTimes.endTime.isSame(bookingTimes.startTime)) {
            setAlertMessage("The end time must be greater than start time");
            let times = Object.assign({}, bookingTimes);
            times.endTime = dayjs(clickToBookOpenSlot.endDate.format("YYYY/MM/DD h:mm a"));
            setBookingTimes(times);

            return;
        }

        let resLength = 1.0;
        let diffInHours = bookingTimes.startTime.diff(bookingTimes.endTime, 'hour', true);
        if (diffInHours < 0) {
            diffInHours = diffInHours * -1;

            resLength = diffInHours;
        }

        // const lengthInMilliSeconds = moment(`1970-01-01 ${bookingTimes.endTime}`, 'YYYY-MM-DD HH:mm') - moment(`1970-01-01 ${bookingTimes.startTime}`, 'YYYY-MM-DD HH:mm');
        // const resLength = (lengthInMilliSeconds / 60000.0) / 60.0;

        setIsSearchingForReservations(true);
        const reservationSearchInput = Object.assign({}, reservationSearchDataDefault);
        reservationSearchInput.searchDate = dayjs(reservationsDate);
        reservationSearchInput.startTime = bookingTimes.startTime;
        reservationSearchInput.endTime = bookingTimes.startTime;
        reservationSearchInput.repeatsUntilDate = null;
        reservationSearchInput.repeatDaysOfWeek = null;
        reservationSearchInput.selectedSport = selectedSport;
        reservationSearchInput.reservationLength = resLength;
        reservationSearchInput.numberOfSpaces = 1;
        reservationSearchInput.customer = currentUser ? userData : null;
        reservationSearchInput.searchByDistance = false;
        reservationSearchInput.searchRadiusInMiles = null;
        reservationSearchInput.searchLatitude = 0.0;
        reservationSearchInput.searchLongitude = 0.0;
        reservationSearchInput.searchByCityState = null;
        reservationSearchInput.searchStateFull = null;
        reservationSearchInput.searchStateAbb = null;
        reservationSearchInput.searchCity = null;
        reservationSearchInput.facilityArea = selectedArea;
        reservationSearchInput.isClickToBook = true;

        const response = await facilityReservationSearch(reservationSearchInput, null, facilityId, null, false, 0.0, 0.0, true);
        if (response.data) {
            const { searchResults, errorMessage, mustPayByInvoice } = response.data;
            if (errorMessage) {
                setSearchResults(searchResults, null);
                setIsSearchingForReservations(false);
                alert(errorMessage);
            } else if (!searchResults || searchResults.length <= 0) {
                setClickToBookOpenSlot(null);
                setSearchResults(searchResults, null);
                setIsSearchingForReservations(false);
                alert("No spaces were found for your search.\n\nPlease refine or change your search criteria and try again.");
            } else {
                setClickToBookOpenSlot(null);
                setBackToSearchUrl(`/reservations/${calendarSettings.searchTerm}`);
                setSearchResults(searchResults, mustPayByInvoice ? 'Invoice' : null);
                setIsSearchingForReservations(false);
                navigate('/reservations/search-results');
            }
        } else {
            setIsSearchingForReservations(false);
            alert("There was an issue with your search, please try again.");
        }
    }

    const closeAlert = () => {
        setAlertMessage('')
    }

    const redirectToSignIn = () => {
        if (searchTerm) {
            setClickToBookOpenSlot(null);
            navigate(`/auth?redirectUrl=/reservations/${searchTerm}/${dayjs(reservationsDate).format("YYYY-MM-DD")}`);
        } else {
            setSignInAlertMessage(null);
        }
    }

    const closeSignInAlert = () => {
        setSignInAlertMessage(null);
    }

    const { facilityArea = {} } = clickToBookOpenSlot ? clickToBookOpenSlot : {};
    const { subAreas = [] } = facilityArea ? facilityArea : [];
    const mainCalendarColor = `${calendarSettings ? calendarSettings.mainFacilityColor : "#14254C"}`;
    const secondaryFacilityColor = `${calendarSettings ? calendarSettings.secondaryFacilityColor : "lightgray"}`;

    return (
        <Popover
            id={'customer-click-to-book-popover'}
            open={clickToBookOpenSlot ? true : false}
            anchorEl={clickToBookOpenSlot ? clickToBookOpenSlot.anchorEl : null}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Box mt={1} mb={1} ml={1} mr={1}>
                <MySportSpaceAlert isOpen={signInAlertMessage ? true : false} message={signInAlertMessage ? signInAlertMessage : ""} okButtonText={'Sign In'} okAction={redirectToSignIn} cancelButtonText={'Cancel'} cancelAction={closeSignInAlert} mainColor={mainCalendarColor} secondaryColor={secondaryFacilityColor}></MySportSpaceAlert>
                <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage ? alertMessage : ""} okButtonText={'OK'} okAction={closeAlert}></MySportSpaceAlert>
                <MySportSpaceLoadingView isOpen={isSearchingForReservations}></MySportSpaceLoadingView>
                <Typography fontWeight={'bold'} mt={2} textAlign={'center'} gutterBottom color={mainCalendarColor} component="div" variant="h5">
                    Book Reservation
                </Typography>
                <Stack ml={1} mr={1} spacing={1}>
                    <InputLabel sx={{ color: secondaryFacilityColor }} required={true} id="selection-list-label">Space</InputLabel>
                    <Box sx={{ marginBottom: '1.0vh', border: '1px solid #DEDEDE', borderRadius: '5px' }}>
                        <Stack mt={1} mb={1} spacing={1}>
                            <FacilityAreaCheckBox key={`box-id-${facilityArea.id}`} label={facilityArea.name} facilityArea={facilityArea} selectedArea={selectedArea} onChange={selectFacilityArea} color={mainCalendarColor}></FacilityAreaCheckBox>
                            {
                                subAreas.length > 0 &&
                                subAreas.map(sArea => (
                                    <FacilityAreaCheckBox key={`box-id-sub-${sArea.id}`} label={sArea.name} facilityArea={sArea} selectedArea={selectedArea} onChange={selectFacilityArea} color={mainCalendarColor}></FacilityAreaCheckBox>
                                ))
                            }
                        </Stack>
                    </Box>
                    <Stack spacing={1} direction={'row'}>
                        <MaterialUITimePicker color={mainCalendarColor} headerText="Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={bookingTimes.startTime} onChange={selectStartTime} minTime={bookingTimesDefault.startTime} />
                        <MaterialUITimePicker color={mainCalendarColor} headerText="End Time" views={['hours', 'minutes']} format={'h:mm A'} value={bookingTimes.endTime} onChange={selectEndTime} />
                    </Stack>
                    <InputLabel sx={{ color: secondaryFacilityColor }} required={true} id="selection-list-label">Sport</InputLabel>
                    <Select
                        labelId={`sports-label`}
                        id={`sports-label-id`}
                        fullWidth
                        value={selectedSport ? selectedSport : ''}
                        onChange={selectSport}
                        input={<OutlinedInput label={"Sport"} sx={{
                            "& .MuiInputBase-input": {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: 'left',
                                color: calendarSettings ? calendarSettings.mainFacilityColor : "#14254C"
                            }
                        }} />}
                        MenuProps={MenuProps}
                    >
                        {sports.map((sport) => (
                            <MenuItem key={sport} value={sport}>
                                <ListItemText primary={sport} />
                            </MenuItem>
                        ))}
                    </Select>
                </Stack>
                <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                    <Stack spacing={1} direction={'row'}>
                        <Button sx={{
                            backgroundColor: mainCalendarColor, ':hover': {
                                bgcolor: 'white',
                                color: mainCalendarColor
                            }
                        }} id={'book-button'} variant="contained" onClick={bookReservation}>Book</Button>
                        <Button sx={{
                            color: mainCalendarColor, borderColor: mainCalendarColor, ':hover': {
                                bgcolor: mainCalendarColor,
                                color: 'white',
                            }
                        }} variant="outlined" onClick={close}>Close</Button>
                    </Stack>
                </Box>
            </Box>
        </Popover>
    )
}

export default ClickToBook;
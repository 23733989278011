import { useEffect, useState, useContext, Fragment } from "react";
import { FacilityAreaContext } from "../../../contexts/facility/facility-area-provider.context";
import { facilityReservationSearch } from "../../../utils/facility-api/facility-service.js";
import { rescheduleReservation } from "../../../utils/reservation-api/reservation-service";
import { UserContext } from "../../../contexts/user.context";
import InputLabel from '@mui/material/InputLabel';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Stack, Button, TextField, Checkbox, Divider, Typography } from "@mui/material";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component.jsx";
import MaterialUITimePicker from "../../material-ui/time-picker/material-ui-time-picker.component.jsx";
import { formatReservationLengthOrDuration } from "../../../utils/reservation-utils/reservation-utils.js";
import FacilityAreaListAll from "../facility-area-list-all/facility-area-list-all.component.jsx";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MySportSpaceAlert from "../../alert/alert.component.jsx";
import dayjs from "dayjs";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx";
import FormControlLabel from '@mui/material/FormControlLabel';
import FacilityResceduleReservationSummary from "./facility-reschedule-reservation-summary.component.jsx";

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const defaultFormFields = {
    rescheduleResStartDate: dayjs(),
    startTime: dayjs().startOf('hour'),
    reservationLength: 1,
    facilityArea: null,
    formOfPayment: null,
    formOfPaymentType: null
}

const FacilityRescheduleReservation = ({ isOpen, reservationToView, facilityCustomer, close, closeResView }) => {

    const { facilityAreas } = useContext(FacilityAreaContext);
    const [facilityAreaPopOverAnchorEl, setFacilityAreaPopOverAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { rescheduleResStartDate, startTime, reservationLength, facilityArea, formOfPayment } = formFields;
    const [alertMessage, setAlertMessage] = useState('');
    const [rescheduledMessage, setRescheduledMessage] = useState(null);
    const { userData } = useContext(UserContext);
    const [isAnyArea, setIsAnyArea] = useState(false);
    const [isAnyMatchingArea, setIsAnyMatchingArea] = useState(false);
    const [rescheduleSearchResults, setRescheduleSearchResults] = useState(null);

    const closeView = () => {
        close()
    }

    const rescheduleWasSuccessful = () => {
        closeResView()
    }

    useEffect(() => {

        const loadView = () => {
            setIsAnyArea(false);
            setIsLoading(true);

            let facilityArea = null;
            for (const area of facilityAreas) {
                if (area.id === reservationToView.mainAreaReserved) {
                    facilityArea = area;
                    break;
                } else if (area.subAreaList) {

                    for (const sArea of area.subAreaList) {
                        if (sArea.id === reservationToView.mainAreaReserved) {
                            facilityArea = sArea;
                            break;
                        }
                    }
                }
            }


            const startDate = dayjs(`${reservationToView.dateKey} ${reservationToView.resStartTime}`, "YYYY-MM-DD h:mm A")
            const endDate = dayjs(`${reservationToView.dateKey} ${reservationToView.resEndTime}`, "YYYY-MM-DD h:mm A")

            const resLength = (endDate.diff(startDate, 'minutes') / 60.0);

            let defaultFormFields = {
                rescheduleResStartDate: dayjs(reservationToView.dateKey, "YYYY-MM-DD"),
                startTime: dayjs(`${reservationToView.dateKey} ${reservationToView.resStartTime}`, "YYYY-MM-DD h:mm A"),
                reservationLength: resLength,
                facilityArea: facilityArea,
                formOfPayment: reservationToView.paymentDetails,
                formOfPaymentType: reservationToView.isPayingWithCredit ? 'Credit Card' : reservationToView.isPayingByInvoice ? "Invoice" : "Cash/Check"
            }
            setFormFields(defaultFormFields);
            setIsLoading(false);
        }

        loadView();

    }, [isOpen]);

    const handleAddAreaClick = (event) => {
        event.preventDefault();
        setFacilityAreaPopOverAnchorEl(facilityAreaPopOverAnchorEl ? null : event.currentTarget);
    };

    const handleCloseAddAreaClick = () => {
        setFacilityAreaPopOverAnchorEl(null);
    };

    const selectRescheduleDate = (rescheduleDate) => {
        if (rescheduleDate) {
            setFormFields({ ...formFields, 'rescheduleResStartDate': rescheduleDate });
        } else {
            setFormFields({ ...formFields, 'rescheduleResStartDate': null });
        }
    }

    const selectStartTime = (startTime) => {
        setFormFields({ ...formFields, 'startTime': startTime });
    }

    const onDurationChange = (event) => {
        let dur = formatReservationLengthOrDuration(event.target.value, reservationLength, true);
        setFormFields({ ...formFields, 'reservationLength': dur.length });
    }

    const selectFacilityAreaId = (facilityAreaId) => {

        for (const fArea of facilityAreas) {
            if (fArea.id === facilityAreaId) {
                setFormFields({ ...formFields, 'facilityArea': fArea });
                break;
            }
            if (fArea.subAreas) {
                const sAreaKeys = Object.keys(fArea.subAreas)
                for (const key of sAreaKeys) {
                    if (key === facilityAreaId) {
                        setFormFields({ ...formFields, 'facilityArea': fArea.subAreas[key] });
                        break;
                    }
                }
            }
        }
        setIsAnyArea(false);
        handleCloseAddAreaClick();
    }

    const rescheduleTheReservation = async (searchResults) => {
        // if (rescheduleSearchResults) {
        //     setRescheduleSearchResults(null);
        // }
       setRescheduleSearchResults(null);
        if (!isLoading) {
            setIsLoading(true);
        }
        const resIdForReschedule = searchResults[0].reservationId;
        const rescheduleResponse = await rescheduleReservation(reservationToView, resIdForReschedule, formOfPayment ? formOfPayment.id : null, false, true);
        if (rescheduleResponse.status === 200 && rescheduleResponse.data && rescheduleResponse.data.status === 'success') {
            setRescheduledMessage(rescheduleResponse.data.message);
        } else {
            setAlertMessage(rescheduleResponse && rescheduleResponse.data && rescheduleResponse.data.error ? rescheduleResponse.data.error : "The reservation is unable to be rescheduled at this time.");
        }

        setIsLoading(false);
    }

    const performReschedule = async () => {
        setIsLoading(true);
        let reservationSearchInput = {
            searchDate: rescheduleResStartDate,
            customer: facilityCustomer,
            startTime: startTime,
            endTime: startTime,
            facilityArea: !isAnyArea && !isAnyMatchingArea ? facilityArea : null,
            facilityAreaType: null,
            selectedSport: reservationToView.reservedForSport,
            searchAreaSubType: "",
            repeatsInterval: null,
            repeatsUntilDate: null,
            repeatDaysOfWeek: [],
            repeatExceptionDates: [],
            reservationLength: reservationLength,
            reschedulingResId: reservationToView.reservationId,
            numberOfSpaces: 1,
            searchAreaTypeOverride: isAnyMatchingArea ? reservationToView.mainAreaReservedType : null,
            searchAreaSubTypeOverride: isAnyMatchingArea && reservationToView.areaSubType ? reservationToView.areaSubType : null,
            ignoreTheseAreas: isAnyArea || isAnyMatchingArea ? reservationToView.areaIds : null,
            customerId: reservationToView.customerId
        }

        const response = await facilityReservationSearch(reservationSearchInput, null, reservationToView.facilityId, userData.internalBookingId, true);
        if (response.data) {
            const { searchResults, errorMessage } = response.data;
            if (response.status === 200 && searchResults && searchResults.length > 0) {
                if (!isAnyArea && !isAnyMatchingArea) {
                    rescheduleTheReservation(searchResults);
                } else {
                    setRescheduleSearchResults(searchResults);
                    setIsLoading(false);
                }

            } else {
                if (errorMessage) {
                    setAlertMessage(errorMessage);
                    setIsLoading(false);
                } else {
                    setAlertMessage("The reservation is unable to be rescheduled at this time.");
                    setIsLoading(false);
                }

            }
        } else {
            setAlertMessage("An unexpected error occurred.");
            setIsLoading(false);
        }

        
    }

    const closeAlertMessage = () => {
        setAlertMessage(null);
    }

    const handleIsAnyArea = () => {
        setIsAnyArea(!isAnyArea);
        setIsAnyMatchingArea(false);
    }

    const handleIsAnyMatchingArea = () => {
        setIsAnyArea(false);
        setIsAnyMatchingArea(!isAnyMatchingArea)
    }

    const cancelReschedule = () => {
        setRescheduleSearchResults(null);
    }

    const subAreas = [];
    if (facilityArea && facilityArea.subAreas) {
        const subAreaIds = Object.keys(facilityArea.subAreas)
        for (const id of subAreaIds) {
            const subArea = facilityArea.subAreas[id]
            subAreas.push(subArea);
        }
    }

    return (
        <Fragment>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            {
                alertMessage &&
                <MySportSpaceAlert title={"Reschedule Error"} message={alertMessage} okButtonText={'OK'} okAction={closeAlertMessage}></MySportSpaceAlert>
            }
            {
                rescheduledMessage &&
                <MySportSpaceAlert title={"Reschedule Confirmation"} message={rescheduledMessage} okButtonText={'OK'} okAction={rescheduleWasSuccessful}></MySportSpaceAlert>
            }
            {
                rescheduleSearchResults && !isLoading && 
                <FacilityResceduleReservationSummary isOpen={true} performReschedule={rescheduleTheReservation} cancelReschedule={cancelReschedule} searchResults={rescheduleSearchResults} reservationToView={reservationToView}></FacilityResceduleReservationSummary>
            }
            {
                !rescheduleSearchResults && !isLoading && !alertMessage && !rescheduledMessage &&
                <Dialog
                    open={isOpen}
                    onClose={closeView}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Reschedule Reservation
                    </DialogTitle>
                    <DialogContent>
                        <Stack mt={2} ml={2} spacing={2}>
                            <Stack spacing={2} direction={'row'} justifyContent={'stretch'}>
                                <MaterialUIDatePicker labelText="Reschedule Date" onChange={selectRescheduleDate} value={rescheduleResStartDate}></MaterialUIDatePicker>
                                <MaterialUITimePicker headerText="Start Time" views={['hours', 'minutes']} format={'h:mm A'} value={startTime} onChange={selectStartTime} />
                                <TextField sx={{ minWidth: '150px' }} id="duration" variant="outlined" label="Duration (Hours)" required type="number" onChange={onDurationChange} name="duration" value={reservationLength}
                                    inputProps={{
                                        pattern: "^\d*\.\d{2}$",
                                        step: userData && userData.isFacilityUserAccount ? "0.25" : "0.5",
                                        max: "24",
                                        min: "0.5"
                                    }}></TextField>
                            </Stack>
                            <Stack spacing={1}>
                                <Divider sx={{ marginTop: '1.0vh', marginBottom: '1.0vh' }}></Divider>
                                <InputLabel required={false} id="selection-list-label">Reschedule To Area</InputLabel>
                                <Stack ml={2} spacing={1}>
                                    {
                                        !isAnyMatchingArea &&
                                        <Stack ml={1}>
                                            <FormControlLabel sx={{ color: '#14254C' }} control={<Checkbox checked={isAnyArea} onChange={handleIsAnyArea} />} label="Any Area" />
                                            <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                                {'Find ANY other area that matches the reserved area sport'}
                                            </Typography>
                                        </Stack>
                                    }
                                    {
                                        !isAnyArea &&
                                        <Stack ml={1}>
                                            <FormControlLabel sx={{ color: '#14254C' }} control={<Checkbox checked={isAnyMatchingArea} onChange={handleIsAnyMatchingArea} />} label="Any Matching Area" />
                                            <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                                {'Find another area that matches the reserved area type'}
                                            </Typography>
                                        </Stack>
                                    }
                                    {!isAnyArea && !isAnyMatchingArea &&
                                        <Stack ml={2} spacing={1}>
                                            <InputLabel required={false} id="selection-list-label">Specific Area</InputLabel>
                                            <Typography ml={3} textAlign={'left'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle2" component="div">
                                                {'Select the area to reschedule to'}
                                            </Typography>
                                            <Button sx={{ marginLeft: '1.0vw' }} variant="outlined" onClick={handleAddAreaClick} >
                                                {facilityArea ? facilityArea.name : defaultFormFields ? defaultFormFields.facilityArea && defaultFormFields.facilityArea.name : "Please Choose Area"}
                                            </Button>
                                        </Stack>
                                    }
                                    <FacilityAreaListAll anchorEl={facilityAreaPopOverAnchorEl} selectedAreaIds={facilityArea ? [facilityArea.id] : []} selectFacilityAreaId={selectFacilityAreaId} closeList={handleCloseAddAreaClick} ></FacilityAreaListAll>
                                </Stack>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeView}>Cancel</Button>
                        <Button variant="contained" onClick={performReschedule} autoFocus>
                            Reschedule
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </Fragment>
    )
}

export default FacilityRescheduleReservation
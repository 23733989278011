
import { useState, Fragment } from "react";
import validator from 'validator';
import MySportSpaceAlert from "../alert/alert.component";
import { addFormOfPayment } from '../../utils/customer-api/customer-service';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';

const defaultFormFields = {
    creditCardNumber: '',
    expMonth: '',
    expYear: '',
    cvc: '',
    zipCode: '',
}

const AddFormOfPayment = ({ open, close, facilityCustomer, formOfPaymentWasAdded }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState(defaultFormFields);
    const { creditCardNumber, expMonth, expYear, cvc, zipCode } = formFields;
    const [alertMessage, setAlertMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const handleClose = () => {
        if (close) {
            close();
        }
    };

    const formOfPaymentAddSuccess = () => {
        if (formOfPaymentWasAdded){
            formOfPaymentWasAdded();
        }

        setSuccessMessage(null);
    }

    const submitAddFormOfPayment = async (event) => {
        event.preventDefault();

        if (!validator.isCreditCard(creditCardNumber)) {
            setAlertMessage("Please enter a valid credit card number.");
            return
        }

        if (!validator.isPostalCode(zipCode, "any")) {
            setAlertMessage("Please enter a valid zip code.");
            return
        }

        if (expMonth > 12 || expMonth < 0) {
            setAlertMessage("");
            return
        }

        setIsLoading(true);

        const response = await addFormOfPayment('card', formFields, facilityCustomer);
        if (response.status === 200) {
            if (!response.data.statusCode || response.data.statusCode === 200) {
                setAlertMessage(null);
                setSuccessMessage("The form of payment has been added.");
            } else {
                if (response.data.raw && response.data.raw.message) {
                    setAlertMessage(response.data.raw.message);
                } else {
                    setAlertMessage("There was an issue adding this form of payment.  The form of payment could not be added.");
                }
            }
        } else {
            setAlertMessage("The form of payment was not added.");
        }
        setIsLoading(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let allowSet = false;
        if (name === "expMonth" || name === "expYear") {
            const regex = /^[0-9\b]+$/;
            if (value === "" || regex.test(value)) {
                allowSet = true;
            }

            if (name === 'expMonth' && (value > 12 || value < 0)) {
                allowSet = false;
            }

            if (name === 'expYear' && (value > 9999 || value < 0)) {
                allowSet = false;
            }
        } else if (name === "creditCardNumber") {
            if (value.length <= 18) {
                allowSet = true;
            }
        } else if (name === 'cvc') {
            if (value.length <= 4) {
                allowSet = true;
            }
        } 
        else {
            allowSet = true;
        }
        if (allowSet) {
            setFormFields({ ...formFields, [name]: value });
        }

    };

    return (
        <Fragment>
            {
                isLoading && <LoadingIndicator></LoadingIndicator>
            }
            {
                alertMessage &&
                <MySportSpaceAlert message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            }
            {
                successMessage &&
                <MySportSpaceAlert message={successMessage} okButtonText={'OK'} okAction={formOfPaymentAddSuccess} ></MySportSpaceAlert>
            }
            {
                !isLoading && !alertMessage && !successMessage &&
                <Fragment>
                    <Dialog
                        open={open && !successMessage ? true : false }
                        onClose={handleClose}
                        // PaperProps={{
                        //     component: 'form',
                        //     onSubmit: (event) => {
                        //         handleSubmit(event);
                        //     },
                        // }}
                    >
                        <DialogTitle>Add Form Of Payment</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Please enter the following to add a form of payment.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                required
                                margin="dense"
                                id="creditCardNumber"
                                name="creditCardNumber"
                                label="Credit Card Number"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                value={creditCardNumber}
                            />
                            <Stack direction={'row'}>
                                <TextField
                                    maxLength="2"
                                    margin="dense"
                                    id="expMonth"
                                    name="expMonth"
                                    label="Exp Month (MM)"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={expMonth}
                                />
                                <TextField
                                    maxLength="4"
                                    margin="dense"
                                    id="expYear"
                                    name="expYear"
                                    label="Exp Year (YYYY)"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={expYear}
                                />
                            </Stack>
                            <Stack direction={'row'}>
                                <TextField
                                    maxLength="3"
                                    margin="dense"
                                    id="cvc"
                                    name="cvc"
                                    label="CVC"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={cvc}
                                />
                                <TextField
                                    maxLength="10"
                                    margin="dense"
                                    id="zipCode"
                                    name="zipCode"
                                    label="Zip Code"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Stack>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button variant="contained" onClick={submitAddFormOfPayment}>Add Form Of Payment</Button>
                        </DialogActions>
                    </Dialog>

                </Fragment >
            }
        </Fragment >
    )

}

export default AddFormOfPayment
import { createContext, useReducer, useContext } from "react";
import { createAction } from "../../utils/reducer/reducer.utils";
import dayjs from "dayjs";

export const facilityEventSearchRequestDefault = {
    location: null,
    sport: null,
    eventType: null,
    startDate: null,
    age: 0,
    grade: null
}

export const FacilityEventSearchContext = createContext({
    facilityEventSearchRequest: Object.assign({}, facilityEventSearchRequestDefault),
    setFacilityEventSearchRequest: () => { },
    reset: () => { },
    searchResults: {},
    setSearchResults: () => []
});

export const FACILITY_EVENT_SEARCH_ACTION_TYPES = {
    SET_FACILITY_EVENT_SEARCH_REQUEST: 'SET_FACILITY_EVENT_SEARCH_REQUEST',
    RESET: 'RESET',
    SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS'
}

const INITIAL_STATE = {
    facilityEventSearchRequest: Object.assign({}, facilityEventSearchRequestDefault),
    searchResults: []
}

const facilityEventSearchReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case FACILITY_EVENT_SEARCH_ACTION_TYPES.SET_FACILITY_EVENT_SEARCH_REQUEST:
            return {
                ...state,
                ...payload
            }
        default:
            throw new Error(`Unhandled type ${type}`);
    }
}

export const FacilityEventSearchRequestProvider = ({children}) => {
    const [{facilityEventSearchRequest, searchResults}, dispatch] = useReducer(facilityEventSearchReducer, INITIAL_STATE);

    const setSearchRequest = (request) => {
        dispatch(createAction(FACILITY_EVENT_SEARCH_ACTION_TYPES.SET_FACILITY_EVENT_SEARCH_REQUEST, { facilityEventSearchRequest: request  }));
    }

    const resetSearchRequest = () => {
        dispatch(createAction(FACILITY_EVENT_SEARCH_ACTION_TYPES.SET_FACILITY_EVENT_SEARCH_REQUEST, { facilityEventSearchRequest: facilityEventSearchRequestDefault  }));
    }

    const setSearchResults = (searchResults) => {
        dispatch(createAction(FACILITY_EVENT_SEARCH_ACTION_TYPES.SET_FACILITY_EVENT_SEARCH_REQUEST, { searchResults: searchResults }));
    }

    const value = {facilityEventSearchRequest, setSearchRequest, resetSearchRequest, searchResults, setSearchResults};

    return <FacilityEventSearchContext.Provider value={value}>{children}</FacilityEventSearchContext.Provider>
}
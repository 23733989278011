import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext } from "react";
import { Stack, Button, Popover, TextField, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { FacilityEventSearchContext } from "../../../contexts/facility-events-search/facility-event-search.context";
import ListWithTextInput from "../../list-with-text-input/list-with-text-input.component";
import SearchIcon from '@mui/icons-material/Search';

const GRADES = [ "Preschool", "PreK", "Kindergarten", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "College" ];

const FacilityEventSearchFilter = ({ isOpen = false, search, close, popoverAnchorEl }) => {

    const { facilityEventSearchRequest, setSearchRequest, setSearchResults } = useContext(FacilityEventSearchContext);
    const { age, grade, startDate } = facilityEventSearchRequest;

    const searchForEvents = () => {
        if (search) {
            search();
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearchRequest({ ...facilityEventSearchRequest, [name]: value });
    }

    const selectGrade = (grade) => {
        setSearchRequest({ ...facilityEventSearchRequest, ['grade']: grade });
    }

    const resetFilter = () => {
        setSearchRequest({ ...facilityEventSearchRequest, ['startDate']: null, ['age']: 0, ['grade']: null});
    }

    const startDateSelected = (date) => {
        if (date) {
            setSearchRequest({ ...facilityEventSearchRequest, ['startDate']: date.format('YYYY-MM-DD') });
        }
    }
    const clearStartDate = () => {
        setSearchRequest({ ...facilityEventSearchRequest, ['startDate']: null });
    }

    const closeFilter = () => {
        if (close) {
            close();
        }
    }

    return (
        <Popover
            id={'search-for-birthday-popover'}
            open={isOpen}
            anchorEl={popoverAnchorEl}
            onClose={closeFilter}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack m={2} spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {!startDate &&
                        <DatePicker label="Start Date" onChange={startDateSelected} slotProps={{
                            field: { clearable: true, onClear: () => clearStartDate() },
                        }}></DatePicker>
                    }

                    {startDate &&
                        <DatePicker label="Start Date" value={dayjs(startDate, "YYYY-MM-DD")} onChange={startDateSelected} slotProps={{
                            field: { clearable: true, onClear: () => clearStartDate() },
                        }}></DatePicker>
                    }

                </LocalizationProvider>
                <TextField type="number" fullWidth id="facility-event-search-age" variant="outlined" label="Age" name="age" value={age} onChange={handleChange} ></TextField>
                <Stack direction={'row'}>
                    <ListWithTextInput variant='outlined' headerText={'Grade'} list={GRADES} selectFromList={selectGrade} selectedItem={grade} allowInput={false} ></ListWithTextInput>
                </Stack>
                <Stack spacing={1} direction={'row'} display={'flex'} justifyContent={'center'}>
                    <Button size="small" onClick={closeFilter}>
                        Close
                    </Button>
                    <Button size="small" variant="outlined" onClick={resetFilter}>
                        Reset
                    </Button>
                </Stack>
            </Stack>
        </Popover>
    )
}

export default FacilityEventSearchFilter
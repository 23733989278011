import { FacilityAreaContext } from '../../../contexts/facility/facility-area-provider.context';
import { useContext } from "react";
import { FacilityAreaContainer } from './facility-areas.styles';
import FacilityAreaRow from './facility-area-row.component';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Stack, Button } from '@mui/material';


const FacilityAreas = ({ selectFacilityArea, selectedFacilityAreas, header = 'Facility Areas', sportFilter, selectAllFacilityAreas }) => {

    const { facilityAreas } = useContext(FacilityAreaContext);

    const allFacilityAreas = [];
    for (const mArea of facilityAreas) {
        if (!sportFilter || mArea.sports.includes(sportFilter)) {
            allFacilityAreas.push(mArea);
        }

        if (mArea.subAreas) {
            for (const subAreaId of Object.keys(mArea.subAreas)) {
                const subArea = mArea.subAreas[subAreaId];
                subArea.id = subAreaId;
                if (!sportFilter || mArea.sports.includes(sportFilter)) {
                    allFacilityAreas.push(subArea);
                }
            }
        }
    }

    const clearSelected = () => {
        selectFacilityArea(null, true);
    }

    const selectAll = () => {
        selectAllFacilityAreas(allFacilityAreas);
    }

    let row = 0;
    return (
        <FacilityAreaContainer>
            <Stack spacing={1} direction={'row'}>
                <Typography sx={{ width: '60%' }} textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                    {header}
                </Typography>
                <Stack spacing={1} direction={'row'} sx={{ width: '40%' }}  display={'flex'} justifyContent={'flex-end'}>
                    <Button size='small' variant='contained' onClick={selectAll}>
                        Select All
                    </Button>
                    <Button size='small' variant='outlined' onClick={clearSelected}>
                        Clear
                    </Button>
                </Stack>

            </Stack>
            <List>
                {
                    allFacilityAreas.map(facilityArea => (
                        <FacilityAreaRow key={facilityArea.id} row={row++} facilityArea={facilityArea} selectedFacilityAreas={selectedFacilityAreas} selectFacilityArea={selectFacilityArea}></FacilityAreaRow>
                    ))
                }
            </List>
        </FacilityAreaContainer>
    )
}

export default FacilityAreas;
import { TextField, Typography, Stack, IconButton, Autocomplete } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import ListWithTextInput from "../../list-with-text-input/list-with-text-input.component";
import { ApplicationDataContext } from "../../../contexts/application-data-context";
import { FacilityEventSearchContext } from "../../../contexts/facility-events-search/facility-event-search.context";
import { SearchForFacilityEvents } from "../../../utils/facility-events/facility-events-service";
import MySportSpaceLoadingView from "../../my-sport-space-loading-view/my-sport-space-loading-view.component";
import MySportSpaceAlert from "../../alert/alert.component";

const FacilityEventsSearch = ({ filterType, facilityId }) => {

    const map = useMap();
    const places = useMapsLibrary('places');

    const { facilityEventSearchRequest, setSearchRequest, setSearchResults } = useContext(FacilityEventSearchContext);

    const { location, sport, eventType } = facilityEventSearchRequest;
    const [sessionToken, setSessionToken] = useState(null);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [predictionResults, setPredictionResults] = useState([]);
    const [locations, setLocations] = useState([]);
    const { sportsSupported } = useContext(ApplicationDataContext);
    const [isLoading, setIsLoading] = useState(false);
    const [alertMessage, setAlerMessage] = useState(null);

    const setUpRequestEventType = (eventType) => {
        setSearchRequest({ ...facilityEventSearchRequest, ['eventType']: eventType });
    }

    useEffect(() => {
        setUpRequestEventType(filterType);
    }, [filterType]);

    useEffect(() => {
        if (!places || !map) return;

        setAutocompleteService(new places.AutocompleteService());
        setPlacesService(new places.PlacesService(map));
        setSessionToken(new places.AutocompleteSessionToken());

        return () => setAutocompleteService(null);
    }, [map, places]);

    const fetchPredictions = async (value) => {

        if (!autocompleteService || !value) {
            return;
        }
        const request = { componentRestrictions: { country: 'us' }, input: value, sessionToken };
        const response = await autocompleteService.getPlacePredictions(request);
        if (response.predictions) {
            const newLocations = [];
            for (const prediction of response.predictions) {
                newLocations.push({
                    label: prediction.description, id: prediction.place_id
                })
            }
            setLocations(newLocations);
            setPredictionResults(response.predictions);
        } else {
            setLocations([]);
            setPredictionResults([]);
        }
    }

    const onLocationChange = (event) => {
        const { value } = event.target;
        fetchPredictions(value);
    }

    const searchForEvents = async () => {

        // if (!location && !sport) {
        //     setAlerMessage('Please provide a location or sport')
        // } else {
            setIsLoading(true);
            const response = await SearchForFacilityEvents(facilityEventSearchRequest);
            if (response && response.data && response.data.searchResults) {
                setSearchResults(response.data.searchResults);
            }
            setIsLoading(false);
        }
    // }

    const selectLocation = (event, newValue) => {
        if (newValue) {
            const { label } = newValue;
            setSearchRequest({ ...facilityEventSearchRequest, ['location']: label });
        } else {
            setSearchRequest({ ...facilityEventSearchRequest, ['location']: null });
        }
    }

    const selectSport = (sport) => {
        setSearchRequest({ ...facilityEventSearchRequest, ['sport']: sport });
    }

    const closeAlert = () => {
        setAlerMessage(false);
    }

    let filteredSports = [];
    for (const sport of sportsSupported) {
        const dashIndex = sport.indexOf('-');
        if (dashIndex >= 0){
            const dashRemovedSport = sport.substring(0, dashIndex).trim();
            if (!filteredSports.includes(dashRemovedSport)) {
                filteredSports.push(dashRemovedSport);
            }
        } else {
            filteredSports.push(sport);
        }

    }

    return (
        <Stack spacing={1}>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage ? alertMessage : ""} okButtonText={'OK'} okAction={closeAlert}></MySportSpaceAlert>
            <Typography noWrap sx={{ fontSize: { xs: '1.5vw', sm: '1.25vw', md: '1.25vw', lg: '1.25vw', xl: '1.25vw' } }} fontWeight={'bold'} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                {`Search ${filterType}s`}
            </Typography>
            {
                !facilityId &&
                <Autocomplete
                    disablePortal
                    options={locations}
                    onChange={selectLocation}
                    renderInput={(params) => <TextField {...params} onChange={onLocationChange} label="Enter Location" />}
                />
            }
            <Stack direction={'row'}>
                <ListWithTextInput variant='outlined' headerText={'Sport'} list={filteredSports.filter((s) => { return s !== 'Birthday Party Package' })} selectFromList={selectSport} selectedItem={sport} allowInput={false} ></ListWithTextInput>
                <IconButton
                    size="medium"
                    aria-label="search-events-btn"
                    onClick={searchForEvents}
                >
                    <SearchIcon></SearchIcon>
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default FacilityEventsSearch;
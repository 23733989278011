import { SearchDateContainer } from "./search-date-input.styles";
import MaterialUIDatePicker from "../../material-ui/date-picker/material-ui-date-picker.component";
import { Fragment, useState, useContext } from "react";
import SearchRepeatsIntervalList from "../search-repeat-interval-list/search-repeat-interval-list.component";
import TwoButtonBar from '../../two-button-bar/two-button-bar.component';
import SearchDaysOfWeekList from '../search-days-of-week-list/search-days-of-week-list.component';
import { TextField, Popover } from "@mui/material";
import dayjs from "dayjs";
import { DAYS_OF_WEEK_INT_TO_TEXT } from '../../../utils/constants/constants';
import { generateDayJSDatesForReservation } from '../../../utils/date-utilities/date-utilities';
import SearchDateExceptionsList from '../../search-for-reservation/search-date-exceptions-list/search-date-exceptions-list.component';
import { SearchForReservationContext } from "../../../contexts/search-for-reservation/search-for-reservation.context";
import { useEffect } from "react";
import { getDaysOfWeekText } from "../../../utils/date-utilities/date-utilities";

const SearchDateInput = ({ dateWasSelected, showDatesInput, position = 'absolute', showShadow = true, showButtons = true, initialDate }) => {

    const { reservationSearchData, setReservationSearchData } = useContext(SearchForReservationContext);
    const { searchDate, repeatsUntilDate, repeatsInterval, daysOfWeek, exceptionDates } = reservationSearchData;

    const [showRepeatsList, setShowRepeatsList] = useState(false);
    const [showDowList, setShowDowList] = useState(false);
    const [showExceptionsList, setShowExceptionsList] = useState(false);
    const [popoverAnchorEl, setPopOverAnchorEl] = useState(null);

    const selectSearchDate = (date) => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.searchDate = date;
        searchData.exceptionDates = [];
        searchData.daysOfWeek = [];
        searchData = handleIntervalChange("None", searchData);
        setReservationSearchData(searchData);
    }

    useEffect(() => {

        async function selectInitialDate(initialDate) {
            if (initialDate) {
                selectSearchDate(initialDate);
            }
        }

        selectInitialDate(initialDate);

    }, []);

    const selectRepeatsUntilDate = (date) => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.repeatsUntilDate = date;
        setReservationSearchData(searchData);
    }

    const doneButtonClicked = () => {
        if (dateWasSelected) {
            dateWasSelected(searchDate, repeatsUntilDate, repeatsInterval, daysOfWeek, exceptionDates, true);
        }
    }

    const clearButtonClicked = () => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.searchDate = dayjs().startOf('day');
        searchData.daysOfWeek = [];
        searchData.repeatsInterval = null;
        searchData.exceptionDates = [];
        searchData.repeatsUntilDate = null;
        setReservationSearchData(searchData);
    }

    const dayOfWeekSelected = (dow, closeList = true) => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.daysOfWeek = dow;
        setReservationSearchData(searchData);
        setShowDowList(false);
    }

    const exceptionDatesSelected = (exceptions, closeList = true) => {
        let searchData = Object.assign({}, reservationSearchData);
        searchData.exceptionDates = exceptions;
        setReservationSearchData(searchData);
        setShowExceptionsList(!closeList);
    }

    const handleIntervalChange = (interval, searchData) => {

        switch (interval) {
            case "None":
                searchData.repeatsUntilDate = null;
                searchData.daysOfWeek = [];
                break;
            case "Daily":
                searchData.daysOfWeek = [];
                searchData.repeatsUntilDate = dayjs(searchDate).add(7, 'days');
                break
            case "Weekly":
                searchData.repeatsUntilDate = dayjs(searchDate).add(2, 'weeks');
                searchData.daysOfWeek = [DAYS_OF_WEEK_INT_TO_TEXT[searchDate.day()]];
                break;
            case "Monthly":
                searchData.repeatsUntilDate = dayjs(searchDate).add(1, 'month');
                searchData.daysOfWeek = [DAYS_OF_WEEK_INT_TO_TEXT[searchDate.day()]];
                break;
            case "Yearly":
                searchData.repeatsUntilDate = dayjs(searchDate).add(1, 'year');
                searchData.daysOfWeek = [DAYS_OF_WEEK_INT_TO_TEXT[searchDate.day()]];
                break;
            default:
                console.log("Invalid")
        }

        return searchData;
    }

    const intervalSelected = (interval) => {
        let searchData = Object.assign({}, reservationSearchData);
        setShowRepeatsList(false);
        if (interval !== "CloseView") {
            searchData.repeatsInterval = interval;
        }

        handleIntervalChange(interval, searchData);

        setReservationSearchData(searchData);
    }

    const handleRepeatsIntervalClick = () => {
        setShowRepeatsList(true);
    }

    const handleDayOfWeekClick = (e) => {
        setShowDowList(true);
    }

    const handleShowExceptionsClick = () => {
        setShowExceptionsList(true);
    }

    const closePopover = () => {
        setPopOverAnchorEl(null);
    }
    const popoverIsOpen = Boolean(popoverAnchorEl);
    return (
        <Fragment>
            {showDatesInput &&
                <SearchDateContainer $position={position} $boxshadow={showShadow ? ' 0 3px 10px rgb(0 0 0 / 0.2)' : 'none'}>
                    <MaterialUIDatePicker labelText="Search Date" minDate={dayjs().startOf('day')} maxDate={dayjs().add(5, 'year')} onChange={selectSearchDate} value={searchDate}></MaterialUIDatePicker>
                    <TextField id="repeats-interval" variant="outlined" label="Repeats" type="text" onClick={handleRepeatsIntervalClick} name="repeats-interval" value={repeatsInterval ? repeatsInterval : "None"} ></TextField>

                    {
                        repeatsInterval && repeatsInterval !== 'None' &&
                        <MaterialUIDatePicker labelText="Repeats Until" minDate={dayjs().startOf('day').add(1, 'day')} maxDate={dayjs().startOf('day').add(5, 'year')} onChange={selectRepeatsUntilDate} value={repeatsUntilDate ? repeatsUntilDate : dayjs().startOf('day').add(1, 'day')}></MaterialUIDatePicker>
                    }

                    {
                        repeatsInterval && repeatsInterval !== 'None' &&
                        <TextField id="repeat-date-exceptions" variant="outlined" label="Date Exceptions" type="text" onClick={handleShowExceptionsClick} name="repeat-date-exceptions" value={exceptionDates.length > 0 ? "Has Exceptions" : "None"} ></TextField>
                    }
                    {
                        showExceptionsList &&
                        <SearchDateExceptionsList searchDates={generateDayJSDatesForReservation(searchDate, repeatsUntilDate, daysOfWeek)} selectedExceptionDates={exceptionDates} exceptionDatesSelected={exceptionDatesSelected}></SearchDateExceptionsList>
                    }

                    {
                        repeatsInterval && (repeatsInterval === 'Weekly' || repeatsInterval === 'Monthly' || repeatsInterval === 'Yearly') &&
                        <TextField id="repeats-dows" variant="outlined" label="Repeat Days Of The Week" type="text" onClick={handleDayOfWeekClick} name="repeat-dows" value={daysOfWeek.length > 0 ? getDaysOfWeekText(daysOfWeek) : "Enter Days Of Week"} ></TextField>
                    }
                    {
                        showDowList &&
                        <SearchDaysOfWeekList isPopOver={false} selectedDayOfWeek={daysOfWeek} dayOfWeekSelected={dayOfWeekSelected}></SearchDaysOfWeekList>
                    }
                    {
                        showButtons &&
                        <div className="button-bar">
                            <TwoButtonBar button1Text={'Reset'} button1Action={clearButtonClicked} button2Text={'Done'} button2Action={doneButtonClicked}></TwoButtonBar>
                        </div>
                    }

                    {showRepeatsList &&
                        <SearchRepeatsIntervalList intervalSelected={intervalSelected}></SearchRepeatsIntervalList>
                    }
                </SearchDateContainer>
            }
        </Fragment>
    )
}

export default SearchDateInput
import { Routes, Route } from 'react-router-dom';
import Home from './routes/home/home.component'
import Navigation from './routes/navigation/navigation.component';
import SignInOrSignUp from './components/sign-in-or-sign-up/sign-in-or-sign-up.component';
import Facility from './routes/facility/facility.compnent';
import Reservations from './routes/reservations/reservations.component';
import FacilityTour from './routes/facility-tour/facility-tour.component';
import { SearchForReservationsProvider } from './contexts/search-for-reservation/search-for-reservation.context';
import { FacilityAreasProvider } from './contexts/facility/facility-area-provider.context';
import { FacilityEventSearchRequestProvider } from './contexts/facility-events-search/facility-event-search.context';
import Bookings from './routes/bookings/bookings.component';
import SignedOutGuardedRoute from './routes/guarded-routes/signed-out-guarded-route.componet';
import { useContext } from 'react';
import { UserContext } from './contexts/user.context';
import CustomerMemberships from './components/memberships/customer-memberships/customer-memberships.component';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CustomerBirthdayPartyRoutes from './routes/customer-birthday-party/customer-birthday-party-routes.component';
import MobileAppInfo from './routes/mobile-app/mobile-app-info.component';
import DeerCreekBaseball from './routes/various-routes/deercreek-baseball.component';
import MobileAppRedirect from './routes/mobile-app/mobile-app-info-redirect.component';
import FacilityEventRoutes from './routes/facility-events/facility-event-routes.component';


const App = () => {

  const { currentUser } = useContext(UserContext);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#14254C',
        dark: '#14254C'
      },
      secondary: {
        main: '#A9A9A9',
      },
    },
    typography: {
      fontFamily: ['Helvetica'],
    },
    components: {
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            color: "#14254C",
          },
        },
      },
      MuiDivider: {
        defaultProps: {
          color: "#A9A9A9",
        }
      },
      MuiTextField: {
        defaultProps: {
          color: "primary",
          inputProps: {
            style: {
              color: "#14254C", // This sets the text field font color
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              color: '#14254C',
            })
        },
      }
      ,
      MuiOutlinedInput: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              color: '#14254C'
            }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: "#14254C",
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            color: "#14254C",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: "#14254C",
          },
        },
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <SearchForReservationsProvider>
        <FacilityAreasProvider>
          <FacilityEventSearchRequestProvider>
            <Routes>
              <Route path='/' element={<Navigation />}>
                <Route index element={<Home />} />
                <Route path='auth/*' element={<SignedOutGuardedRoute currentUser={currentUser}><SignInOrSignUp /></SignedOutGuardedRoute>} />
                <Route path='facility/*' element={<Facility />} />
                <Route path='reservations/*' element={<Reservations />} />
                <Route path='bookings/*' element={<Bookings />} />
                <Route path='facilityinfo/*' element={<FacilityTour />} />
                <Route path='facility-tour/*' element={<FacilityTour />} />
                <Route path='memberships' element={<CustomerMemberships />} />
                <Route path='birthday-parties/*' element={<CustomerBirthdayPartyRoutes />} />
                <Route path='mobile-app' element={<MobileAppInfo />} />
                <Route path='mobile-app-redirect' element={<MobileAppRedirect />} />
                <Route path='leagues/*' element={<FacilityEventRoutes />} />
                <Route path='camps/*' element={<FacilityEventRoutes />} />
                <Route path='tournaments/*' element={<FacilityEventRoutes />} />
                <Route path="*" element={<Home />} />
                <Route path='deercreekbaseball' element={<DeerCreekBaseball></DeerCreekBaseball>} />
              </Route>
            </Routes>
          </FacilityEventSearchRequestProvider>
        </FacilityAreasProvider>
      </SearchForReservationsProvider>
    </ThemeProvider>
  )
};

export default App;

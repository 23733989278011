import { CustomerSignInOrSignupContainer } from './sign-in-or-sign-up.styles';
import SignInForm from '../sign-in-form/sign-in-form.component';
import CustomerSignup from '../customer/customer-sign-up/customer-sign-up.component';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { Fragment, useContext } from 'react';
import LoadingIndicator from '../loading-indicator/loading-indicator.component';

const SignInOrSignUp = ({ props }) => {

    const [searchParams] = useSearchParams();
    const redirectUrl = searchParams.get('redirectUrl');
    const { currentUser, userIsLoading } = useContext(UserContext);

    return (

        <CustomerSignInOrSignupContainer>
            {
                userIsLoading &&
                <LoadingIndicator></LoadingIndicator>
            }
            {!userIsLoading && !currentUser &&
                <Fragment>
                    <div className='sign-up-conatiner'>
                        <CustomerSignup navigateLink={redirectUrl}></CustomerSignup>
                    </div>
                    <div className='sign-in-container'>
                        <SignInForm navigateLink={redirectUrl}></SignInForm>
                    </div>
                </Fragment>
            }
        </CustomerSignInOrSignupContainer>

    )
}

export default SignInOrSignUp;
import { Card, Box, CardContent, Stack, Typography } from "@mui/material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';

const logoImageStyle = {
    height: '4.0vh',
    width: '4.0vw',
    objectFit: 'contain'
};

const FacilityEventsListCard = ({ searchResult, backToUrl }) => {

    const { id, facilityId, facilityName, facilityLogoUrl, eventType, masterEventId, name, gradeStart, gradeEnd, eventStartsDate, timeStart, timeEnd, sport, price, address, state = '', city = '', zipCode = '', ageRangeBegin, ageRangeEnd, anyAge, underOrOverAgeIndicator, underOverAge } = searchResult;

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();

        const eventTypeValue = eventType.toUpperCase();
        let eventMainPath = '';
        switch (eventTypeValue) {
            case 'LEAGUE':
                eventMainPath = 'leagues';
                break;
            case 'TOURNAMENT':
                eventMainPath = 'tournaments';
                break;
            case 'CAMP':
                eventMainPath = 'camps';
                break;
            default:
                eventMainPath = 'events';
        }

        if (backToUrl) {
            navigate(`/${eventMainPath}/${facilityId}/${id}?redirectUrl=${backToUrl}`);
        } else {
            navigate(`/${eventMainPath}/${facilityId}/${id}`);
        }

    }

    const locationAddress = address ? `${address}, ${city} ${state} ${zipCode}` : null;
    const gradeInfo = gradeStart && gradeEnd ? `Grades ${gradeStart} - ${gradeEnd}` : gradeStart ? `Grade ${gradeStart}` : gradeEnd ? `Grade ${gradeEnd}` : null;
    const ageInfo = anyAge ? 'All Ages' : ageRangeBegin && ageRangeEnd ? `Ages ${ageRangeBegin} - ${ageRangeEnd}` : underOrOverAgeIndicator ? `${underOverAge} ${underOrOverAgeIndicator}` : null;
    return (
        <Card  sx={{cursor: "pointer", ":hover": { boxShadow: `0 3px 10px #14254C`}
        }} onClick={handleClick}>
            <CardContent sx={{ display: "flex", flexDirection: 'column' }}>
                <Stack direction={'row'} alignContent={'center'} alignItems={'center'}>
                    <Box display={'flex'} justifyContent={'center'}>
                        <img style={logoImageStyle} className="facility-event-logo-image" src={facilityLogoUrl ? facilityLogoUrl : ""} alt="Logo" />
                    </Box>
                    <Typography noWrap sx={{ fontSize: '0.90vw' }} textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                        {facilityName}
                    </Typography>
                </Stack>
                <Stack mt={1} ml={3} spacing={1}>
                    {
                        masterEventId &&
                        <Typography sx={{ fontSize: '0.90vw' }} gutterBottom textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {masterEventId.toUpperCase()}
                        </Typography>
                    }
                    <Typography sx={{ fontSize: '0.70vw' }} gutterBottom textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'gray'} variant="subtitle1" component="div">
                        {name.toUpperCase()}
                    </Typography>
                    <Stack spacing={1} direction={'row'} alignContent={'center'} alignItems={'center'}>
                        <CalendarTodayOutlinedIcon fontSize="small" />
                        <Typography noWrap sx={{ fontSize: '0.75vw' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {dayjs(eventStartsDate, 'YYYY-MM-DD').format('ddd, MMM YYYY').toUpperCase()}
                        </Typography>
                    </Stack>
                    {
                        locationAddress &&
                        <Stack spacing={1} direction={'row'} alignContent={'center'} alignItems={'center'}>
                            <LocationOnOutlinedIcon fontSize="small" />
                            <Typography sx={{ fontSize: '0.75vw' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                {locationAddress.replace('/n', "").toUpperCase()}
                            </Typography>
                        </Stack>
                    }
                    <Stack useFlexGap spacing={1} direction={'row'} sx={{ flexWrap: 'wrap' }}>
                        <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {`$${price}`}
                        </Typography>
                        {
                            timeStart && timeEnd &&
                            <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                {`${timeStart} - ${timeEnd}`}
                            </Typography>
                        }
                        {
                            timeStart && !timeEnd &&
                            <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                {timeStart}
                            </Typography>
                        }
                        <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                            {sport.toUpperCase()}
                        </Typography>
                        {
                            gradeInfo &&
                            <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                {gradeInfo.toUpperCase()}
                            </Typography>
                        }
                                                {
                            ageInfo &&
                            <Typography paddingLeft={1} paddingRight={1} noWrap sx={{ fontSize: '0.70vw', backgroundColor: '#DEDEDE', borderRadius: '5px' }} gutterBottom textAlign={'left'} fontStyle={'Helvetica'} color={'#14254C'} variant="subtitle1" component="div">
                                {ageInfo.toUpperCase()}
                            </Typography>
                        }
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default FacilityEventsListCard;
import { CustomerSignupContainer } from './customer-sign-up.styles';
import { useState } from 'react';
import validator from 'validator';
import { createCustomerUser, sendEmailVerification } from "../../../utils/customer-api/customer-service";
import MySportSpaceAlert from '../../alert/alert.component';
import { useNavigate } from 'react-router-dom';
import MySportSpaceLoadingView from '../../my-sport-space-loading-view/my-sport-space-loading-view.component';
import AddressForm from '../../address-form/address-form.component';
import { Stack, Typography, TextField, Button } from '@mui/material';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

const isUSAZipCode = (str) => {
    return /^\d{5}(-\d{4})?$/.test(str);
}

const defaultFormFields = {
    email: '',
    verifyemail: '',
    password: '',
    verifypassword: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: ''
}

const CustomerSignup = ({ navigateLink }) => {

    const [formFields, setFormFields] = useState(defaultFormFields);
    const [showPassword, setShowPassword] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const navigate = useNavigate();

    const handleChange = (event) => {

        const { name, value } = event.target;
        setFormFields({ ...formFields, [name]: value });
    };

    const closeAlert = () => {
        setAlertMessage(null);
    }

    const { email, verifyemail, password, verifypassword, firstName, lastName, phoneNumber, address1, address2, city, state, zipCode } = formFields;

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (email === '' || !validator.isEmail(email)) {
            alert(`Invalid Email Address`);
            return;
        }

        if (phoneNumber === '' || !validator.isMobilePhone(phoneNumber)) {
            alert('Invalid Phone Number');
            return;
        }

        if (verifyemail !== email) {
            alert("Emails Do Not Match")
            return;
        }

        if (verifypassword !== password) {
            alert("Passwords Do Not Match")
            return;
        }

        if (!password.match(passwordRegex)) {
            alert("Invalid Password")
            return;
        }

        if (!isUSAZipCode(zipCode)) {
            alert("Invalid Zip Code");
            return;
        }

        setIsLoading(true);
        const CreateCustomerUserRequest = {
            password: password,
            userEmailAddress: email,
            userFirstName: firstName,
            userLastName: lastName,
            userPhoneNumber: phoneNumber,
            userMustResetPassword: false,
            createFirebaseUser: true,
            contactInfo: {
                mailingAddress1: address1,
                mailingAddress2: address2,
                mailingCity: city,
                mailingState: state,
                mailingZipCode: zipCode
            }
        };

        const createCustomerResponse = await createCustomerUser(CreateCustomerUserRequest);
        if (createCustomerResponse.status === 200 && createCustomerResponse.data.status === 'success') {
            sendEmailVerification(email);
            // await signInAuthUserWithEmailAndPassword(email, password);
            clearForm();
            setIsLoading(false);
            setSuccessMessage('Your My Sport Space account has been created.\n\n An email has been sent to your specified email address.\n\nAccess your email and click on the link before logging in.If you have not received an email within 5 minutes, be sure to check your spam and/or junk folder.\n\nYou will not able to sign in until your email address has been verified.');
        } else {
            setIsLoading(false);
            if (createCustomerResponse.data.errorMessage) {
                setAlertMessage(createCustomerResponse.data.errorMessage);
            } else {
                setAlertMessage('Your sign up could not be completed.\n\nPlease try again.')
            }
        }
    }

    const clearForm = () => {
        setFormFields(defaultFormFields);
    }

    const goToHomePage = () => {
        if (navigateLink) {
            navigate(navigateLink);
        } else {
            navigate('/');
        }
    }

    return (
        <CustomerSignupContainer>
            <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
            <MySportSpaceAlert isOpen={successMessage ? true : false} title='Account Created' message={successMessage} okButtonText={'OK'} okAction={goToHomePage} ></MySportSpaceAlert>
            <MySportSpaceAlert isOpen={alertMessage ? true : false} message={alertMessage} okButtonText={'OK'} okAction={closeAlert} ></MySportSpaceAlert>
            <form onSubmit={handleSubmit}>
                <Stack spacing={1}>
                    <Typography textAlign={'left'} fontWeight={'bold'} fontStyle={'Helvetica'} color={'#14254C'} variant="h6" component="div" mb={1} ml={1}>
                        New Customer Sign Up
                    </Typography>
                    <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signup-email" variant="outlined" label="Email Address" type="email" name="email" value={email} onChange={handleChange} ></TextField>
                    <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signup-verifyemail" variant="outlined" label="Verify Email Address" type="email" name="verifyemail" value={verifyemail} onChange={handleChange} ></TextField>
                    <TextField helperText="Password must be 8 - 15 characters and contain atleast one lowercase, uppercase, special character and number" required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signup-password" variant="outlined" label="Password" type={showPassword ? "text" : "password"} name="password" value={password} onChange={handleChange} ></TextField>
                    <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signup-verifypassword" variant="outlined" label="Verify Password" type={showPassword ? "text" : "password"} name="verifypassword" value={verifypassword} onChange={handleChange} ></TextField>
                    <Stack spacing={0} direction={'row'}>
                        <TextField required fullWidth sx={{ mr: '1.0vw' }} id="customer-signup-firstname" variant="outlined" label="First Name" type="text" name="firstName" value={firstName} onChange={handleChange} ></TextField>
                        <TextField required fullWidth id="customer-signup-lastname" variant="outlined" label="Last Name" type="text" name="lastName" value={lastName} onChange={handleChange} ></TextField>
                    </Stack>
                    <TextField required fullWidth sx={{ ml: '1.0vw', mr: '1.0vw' }} id="customer-signup-phonenumber" variant="outlined" label="Phone Number" type="phoneNumber" name="phoneNumber" value={phoneNumber} onChange={handleChange} ></TextField>
                    <AddressForm defaultFormFields={formFields} handleChange={handleChange}></AddressForm>
                    <Button type="submit" variant="contained">Sign Up</Button>
                </Stack>
            </form>
        </CustomerSignupContainer>
    )
}

export default CustomerSignup;
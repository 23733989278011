
import { Box, Stack } from "@mui/material"
import MaterialUIDatePicker from '../../material-ui/date-picker/material-ui-date-picker.component';
import { useContext, useEffect, useState } from 'react';
import dayjs from "dayjs";
import BottomButtonBar from '../../bottom-button-bar/bottom-button-bar.component';
import MaterialUITimePicker from '../../material-ui/time-picker/material-ui-time-picker.component';
import { BirthdayPartyContext } from "../../../contexts/birthday-party/birthday-party.context";
import { UserContext } from "../../../contexts/user.context";
import FacilityCustomerSearch from "../../facility/facility-customer-search/facility-customer-search.component";


const BirthdaySearchDateTime = ({ searchForParty, close, mainTextColor = '#14254C', secondaryTextColor = "darygray" }) => {

    const { partyInput, setPartyInput, setIsLoading } = useContext(BirthdayPartyContext);
    const { userData } = useContext(UserContext);
    const [searchingForCustomers, setSearchingForCustomers] = useState(false);

    useEffect(() => {
        if (partyInput.date.isBefore(dayjs().startOf('hour'))) {
            setPartyInput({ ...partyInput, 'date': dayjs().startOf('hour') });
        }
    }, []);


    const selectPartyDate = (value) => {
        setPartyInput({ ...partyInput, 'date': value });
    }

    const selectStartTime = (time) => {
        setPartyInput({ ...partyInput, 'startTime': time });
    }

    const performSearchForParty = () => {
        if (searchForParty) {
            searchForParty(partyInput.date, partyInput.startTime);
        }
    }

    const cancelSearch = () => {
        if (close) {
            close();
        }
    }

    const handleCustomerChange = (customerName, customer) => {
        setPartyInput({ ...partyInput, 'customer': customer, 'customerName': customerName });
    }

    const setIsSearchingForCustomers = (isSearching) => {
        setSearchingForCustomers(isSearching);
    }

    return (

        <Box sx={{ margin: '2.0vw', minHeight: '25vh', minWidth: '25vw' }}>
            <Stack spacing={2}>
                <MaterialUIDatePicker disabled={searchingForCustomers} labelText="Birthday Party Date:" minDate={dayjs().startOf('day')} onChange={selectPartyDate} value={partyInput.date}></MaterialUIDatePicker>
                <MaterialUITimePicker disabled={searchingForCustomers} headerText="Start Time:" views={['hours', 'minutes']} format={'h:mm A'} value={partyInput.startTime} onChange={selectStartTime} />
                {
                    userData && userData.facilityId &&
                    <FacilityCustomerSearch customer={partyInput.customer} searchCustomerName={partyInput.customerName} handleCustomerChange={handleCustomerChange} setIsSearchingForCustomers={setIsSearchingForCustomers} marginLeft="0" marginRight="0"></FacilityCustomerSearch>
                }
            </Stack>
            {!searchingForCustomers &&
                <BottomButtonBar mainColor={`${mainTextColor}`} secondaryColor={`${secondaryTextColor}`} okButtonText={'Search'} okAction={performSearchForParty} cancelButtonText={"Cancel"} cancelAction={cancelSearch}></BottomButtonBar>
            }
        </Box>

    )
}

export default BirthdaySearchDateTime;
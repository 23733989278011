import { AreaColumnContainer } from './calendar-area-column.styles.jsx'
import CalendarOpenReservation from '../calendar-open-reservation/calendar-open-reservation.component.jsx';
import OpenTimeCalendarRow from '../open-time-calendar-row/open-time-calendar-row.component.jsx'
import { findOpenTimesForArea, findOpenTimesForSubArea, findOpenBookingTimeSlots } from "../../../../utils/reservation-utils/reservation-utils.js";
import { useTextWidth } from '@tag0/use-text-width';
import { useState, useEffect, Fragment, useContext } from 'react';
import CalendarReservation from '../caldendar-reservation/calendar-reservation.component.jsx';
import { CalendarSettingsContext } from '../../../../contexts/customer/calendar-settings.context.jsx';
import MySportSpaceLoadingView from '../../../my-sport-space-loading-view/my-sport-space-loading-view.component.jsx';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2


const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const momentTz = require('moment-timezone');

const defaultReservationData = {
    blackoutsForArea: [],
    blackoutsForSubAreas: [],
    nonBlackoutsForArea: [],
    nonBlackoutsForSubAreas: [],
    allSubAreaOpenSlots: [],
    allMainAreaOpenSlots: {
        openTimes: []
    },
    nameForWidth: 15.0,
    openBookingTimeSlots: []
}


const CalendarAreaColumn = ({ facilityArea, column, calendarData = {}, facilityId, facilityName, calendarDate }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [reservationData, setReservationData] = useState(defaultReservationData);
    const { calendarSettings = {} } = useContext(CalendarSettingsContext);
    const { secondaryFacilityColor = "#DEDEDE", mainFacilityColor = '#14254C' } = calendarSettings;

    const { hoursOfOperation, facilityTimeZone, openBookingTimes, timeSlots = 0 } = calendarData;

    useEffect(() => {

        const processReservationData = async (facilityArea, hoursOfOperation) => {
            setIsLoading(true);
            const endDateTime = hoursOfOperation.endDateTime.clone().endOf('hour').add(1, 'minute');
            const startDateTime = hoursOfOperation.startDateTime.startOf('hour').clone();

            let nameForWidth = facilityArea.name;
            const { reservations = [], subAreas } = facilityArea;
            const allMainAreaReservations = [];

            let allSubAreaOpenSlots = [];
            let allMainAreaOpenSlots = [];

            if (reservations) {
                for (const reservation of reservations) {
                    reservation.startDate = momentTz.tz(reservation.dateKey + ' ' + reservation.resStartTime, "YYYY-MM-DD h:mm A", facilityTimeZone);
                    let endDate = momentTz.tz(reservation.dateKey + ' ' + reservation.resEndTime, "YYYY-MM-DD h:mm A", facilityTimeZone);
                    if (reservation.resEndTime === '12:00 AM') {
                        endDate = endDate.add(1, 'day');
                    }
                    reservation.endDate = endDate;
                    allMainAreaReservations.push(reservation);
                }
            }
            allMainAreaOpenSlots = findOpenTimesForArea(facilityTimeZone, facilityArea, allMainAreaReservations, hoursOfOperation.facilityOpenDateTime, hoursOfOperation.facilityCloseDateTime);

            const allSubAreaReservations = [];
            let openBookingTimeSlots = [];
            if (subAreas && subAreas.length > 0) {
                let columnCount = 1;
                let longestSubAreaName = "";
                const subAreaArr = [];
                const sortedSubAreas = subAreas.sort((sub1, sub2) => {
                    if (sub1.sortOrder < sub2.sortOrder) {
                        return -1;
                    }

                    if (sub1.sortOrder > sub2.sortOrder) {
                        return 1;
                    }

                    return 0;
                })
              
                for (const subArea of sortedSubAreas) {

                    const { reservations = [] } = subArea;
                    if (subArea.name.length > longestSubAreaName.length) {
                        longestSubAreaName = subArea.name;
                    }
                    subArea.column = columnCount;
                    subAreaArr.push(subArea);

                    if (Object.keys(openBookingTimes).length > 0) {
                        const subOpenBookingSlots = findOpenBookingTimeSlots(openBookingTimes, subArea, hoursOfOperation.facilityOpenDateTime, hoursOfOperation.facilityCloseDateTime);
                        openBookingTimeSlots = openBookingTimeSlots.concat(subOpenBookingSlots);
                    }

                    columnCount += 1;

                    for (const reservation of reservations) {
                        reservation.startDate = momentTz.tz(reservation.dateKey + ' ' + reservation.resStartTime, "YYYY-MM-DD h:mm A", facilityTimeZone);
                        let endDate = momentTz.tz(reservation.dateKey + ' ' + reservation.resEndTime, "YYYY-MM-DD h:mm A", facilityTimeZone);
                        if (reservation.resEndTime === '12:00 AM') {
                            endDate = endDate.add(1, 'day');
                        }
                        reservation.endDate = endDate;
                        allSubAreaReservations.push(reservation);
                    }
                }
                if (longestSubAreaName.length > nameForWidth.length) {
                    nameForWidth = longestSubAreaName;
                }
                allSubAreaOpenSlots = findOpenTimesForSubArea(allSubAreaReservations, subAreaArr, facilityTimeZone, hoursOfOperation.facilityOpenDateTime, hoursOfOperation.facilityCloseDateTime);
            }
            else {
                if (Object.keys(openBookingTimes).length > 0) {
                    openBookingTimeSlots = findOpenBookingTimeSlots(openBookingTimes, facilityArea, startDateTime, endDateTime);
                }
            }

            const reservationCategories = {
                blackoutsForArea: allMainAreaReservations.filter((res) => {
                    return res.isBlackOutPeriod;
                }),
                blackoutsForSubAreas: allSubAreaReservations.filter((res) => {
                    return res.isBlackOutPeriod;
                }),
                nonBlackoutsForArea: allMainAreaReservations.filter((res) => {
                    return !res.isBlackOutPeriod;
                }),
                nonBlackoutsForSubAreas: allSubAreaReservations.filter((res) => {
                    return !res.isBlackOutPeriod;
                }),
                allSubAreaOpenSlots: allSubAreaOpenSlots,
                allMainAreaOpenSlots: allMainAreaOpenSlots,
                nameForWidth: nameForWidth,
                openBookingTimeSlots: openBookingTimeSlots

            }
            setReservationData(reservationCategories);
            setIsLoading(false);
        }

        processReservationData(facilityArea, hoursOfOperation);
    }, [facilityArea, hoursOfOperation]);


    const { blackoutsForArea, blackoutsForSubAreas, nonBlackoutsForArea, nonBlackoutsForSubAreas, allSubAreaOpenSlots, allMainAreaOpenSlots, nameForWidth, openBookingTimeSlots } = reservationData;

    let facilityAreaSize = 1;
    if (facilityArea && facilityArea.subAreas) {
        facilityAreaSize = facilityArea.subAreas.length;
    }
    var rows = [], i = 1;
    while (++i < timeSlots) rows.push(i);

    let columnWidth = useTextWidth({ text: nameForWidth, font: '15px Times' });
    if (columnWidth < 100.0) {
        columnWidth = 100.0;
    }
    const openBookingTimesAreActive = Object.keys(openBookingTimes).length > 0;

    return (
        <Grid sx={{ width: '100%', height: '100%' }} container columns={facilityAreaSize}>
            <AreaColumnContainer $numberofcolumns={facilityAreaSize} $numberofrows={timeSlots} $secondaryfacilitycolor={secondaryFacilityColor} >
                <MySportSpaceLoadingView isOpen={isLoading}></MySportSpaceLoadingView>
                {!isLoading &&
                    <Fragment>
                        {
                            rows.map((row) => {
                                return <OpenTimeCalendarRow key={`${row}-{reservation.reservationId}`} rowNumber={row} totalColumns={facilityAreaSize} borderColorIn={openBookingTimesAreActive ? `${mainFacilityColor}80` : '#DEDEDE'} backgroundColor={openBookingTimesAreActive ? 'rgba(0, 0, 0, 0.03)' : '#DEDEDE10'} activeOpenBookingTimes={openBookingTimesAreActive}></OpenTimeCalendarRow>
                            })
                        }
                        {
                            openBookingTimeSlots.length > 0 &&
                            openBookingTimeSlots.map((openslot) => {
                                return <CalendarOpenReservation facilityName={facilityName} facilityId={facilityId} facilityArea={openslot.facilityArea} key={`calendar-sub-open-slot-${openslot.id}`} openSlot={openslot} column={'1'} bgColor={`${secondaryFacilityColor}50`} hoursOfOperation={hoursOfOperation} border={`1px solid #DEDEDE`} > </CalendarOpenReservation>
                            })
                        }
                        {!openBookingTimesAreActive &&
                            allMainAreaOpenSlots.openTimes.map((openslot) => {
                                return <CalendarOpenReservation facilityName={facilityName} facilityId={facilityId} facilityArea={openslot.facilityArea} key={`calendar-open-slot-${openslot.id}`} openSlot={openslot} column={'1'} hoursOfOperation={hoursOfOperation}> </CalendarOpenReservation>
                            })
                        }
                        {!openBookingTimesAreActive &&
                            allSubAreaOpenSlots.map((openslot) => {
                                return <CalendarOpenReservation facilityName={facilityName} facilityId={facilityId} facilityArea={openslot.facilityArea} key={`calendar-sub-open-slot-${openslot.id}`} openSlot={openslot} column={'1'} hoursOfOperation={hoursOfOperation}> </CalendarOpenReservation>
                            })
                        }
                        {
                            blackoutsForArea && blackoutsForArea.map((reservation) => {
                                return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                            })
                        }
                        {
                            blackoutsForSubAreas && blackoutsForSubAreas.map((reservation) => {
                                return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                            })
                        }
                        {
                            nonBlackoutsForSubAreas && nonBlackoutsForSubAreas.map((reservation) => {
                                return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                            })
                        }
                        {
                            nonBlackoutsForArea && nonBlackoutsForArea.map((reservation) => {
                                return <CalendarReservation key={reservation.reservationId} facilityArea={facilityArea} reservation={reservation} hoursOfOperation={hoursOfOperation}></CalendarReservation>
                            })
                        }
                    </Fragment>
                }
            </AreaColumnContainer>
        </Grid>
    )
}

export default CalendarAreaColumn
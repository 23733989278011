import baseApiService from "../base-api/base-api-service";

export const GetFacilitiesWithEvents = async(type) => {
    try {
        let url = `events/all-facility-events`;
        if (type) {
            url = `${url}?eventType=${type}`
        }
        return await baseApiService.get(url);
     } catch (err) {
        console.log(err);
        return err;
     }
}

export const GetUpcomingEvents = async(type, facilityId) => {
    try {
        let url = `events/upcoming-facility-events?eventType=${type}&facilityId=${facilityId}`;
        return await baseApiService.get(url);
     } catch (err) {
        console.log(err);
        return err;
     }
}

export const SearchForFacilityEvents = async (request) => {
    try {
        return await baseApiService.post('events/search', request);
     } catch (err) {
        console.log(err);
        return err;
     }
}

export const SignupForEvent = async (facilityEvent, customerId, formFields, additionalInfo) => {

    const additionalInfoArr = [];
    if (additionalInfo) {
        const additionalInfoKeys = Object.keys(additionalInfo)
        for (const key of additionalInfoKeys) {
            const value = {
                text: key,
                answer: additionalInfo[key]
            }

            additionalInfoArr.push(value);
        }
    }

    const SignupRequest = {
        facilityEventId: facilityEvent.id,
        customerId: customerId, 
        facilityId: facilityEvent.facilityId, 
        paymentMethodId: formFields.paymentMethodId,
        playerOrTeamName: formFields.playerOrTeamName,
        coachFirstName: formFields.coachFirstName && formFields.coachFirstName.length > 0 ? formFields.coachFirstName :  null,
        coachLastName: formFields.coachLastName && formFields.coachLastName.length > 0 ? formFields.coachLastName :  null,
        coachPhoneNumber: formFields.coachPhoneNumber && formFields.coachPhoneNumber.length > 0 ? formFields.coachPhoneNumber :  null,
        coachEmailAddress: formFields.coachEmailAddress && formFields.coachEmailAddress.length > 0 ? formFields.coachEmailAddress :  null,
        facilityMembershipId: facilityEvent.facilityMembershipId,
        additionalInfo: additionalInfoArr.length ? additionalInfoArr : null
    }

    try {
        return await baseApiService.post('events/customer-sign-up', SignupRequest);
     } catch (err) {
        console.log(err);
        return err;
     }
}

export const GetFacilityEventDetails = async(facilityEventId, customerId, facilityId) => {
    try {
        let url = `events/facility-event-details`;
        if (facilityEventId) {
            url = `${url}?facilityEventId=${facilityEventId}`
        }
        if (customerId) {
            url = `${url}&customerId=${customerId}`
        }
        if(facilityId) {
            url = `${url}&facilityId=${facilityId}`
        }

        return await baseApiService.get(url);
     } catch (err) {
        console.log(err);
        return err;
     }
}